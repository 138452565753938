/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */

/* eslint-disable react-native/no-inline-styles */
window.Buffer = window.Buffer || require("buffer").Buffer;

import React, { useCallback, useEffect, useState } from "react";

import { FontAwesome } from "@expo/vector-icons";
import { uuidv4 } from "@firebase/util";
import { manipulateAsync } from "expo-image-manipulator";
import * as ImagePicker from "expo-image-picker";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import {
  FlatList,
  Image,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { Provider } from "react-native-paper";

import MultiSelectInput from "../components/MultiSelectInput";
import TextInput from "../components/TextInput";
import { ActivityIndicator, Text, View } from "../components/Themed";
import Colors from "../constants/Colors";
import Layout from "../constants/Layout";
import Styles from "../constants/Styles";
import useColorScheme from "../hooks/useColorScheme";
import {
  Food,
  FoodCusine,
  FoodTime,
  FoodType,
  RootTabScreenProps,
} from "../types";
import { _searchList, getDefaultThumbnail } from "../utils/helper";

//const analytics = getAnalytics(app);

export default function AdminFoodList({
  navigation,
}: RootTabScreenProps<"Discover">) {
  const theme = useColorScheme();

  const [isLoading, setIsLoading] = useState(true);
  const [userPhone, setUserPhone] = useState<string | undefined | null>();
  const [foodList, setFoodList] = useState<Food[]>([]);
  const [filteredFoodList, setFilteredFoodList] = useState<Food[]>();
  const [food, setFood] = useState<Food>();
  const [searchText, setSearchText] = useState<string>();

  const db = getFirestore();

  useEffect(() => {
    if (getAuth().currentUser) {
      setUserPhone(getAuth().currentUser?.phoneNumber);
    }
  }, []);

  useEffect(() => {
    if (searchText && searchText !== "") {
      setFilteredFoodList(_searchList(foodList, searchText));
    } else {
      setFilteredFoodList(undefined);
    }
  }, [foodList, searchText]);

  async function uploadImageAsync(uri: string) {
    // Why are we using XMLHttpRequest? See:
    // https://github.com/expo/expo/issues/2402#issuecomment-443726662

    const manipResult = await manipulateAsync(
      uri,
      [{ resize: { width: 250 } }],
      { compress: 1 }
    );
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        console.log(e);
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", manipResult.uri, true);
      xhr.send(null);
    });

    const fileRef = ref(getStorage(), uuidv4());
    await uploadBytes(fileRef, blob);

    // We're done with the blob, close and release it
    //blob.close();

    return await getDownloadURL(fileRef);
  }

  const pickImage = useCallback(
    async (index?: number) => {
      // No permissions request is necessary for launching the image library
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 1,
      });

      console.log(result);

      if (!result.cancelled) {
        const image_url = await uploadImageAsync(result.uri);
        console.log(image_url);
        const foodImages = food?.foodImages || [];
        if (index === undefined) {
          foodImages.push(image_url);
        } else {
          foodImages[index] = image_url;
        }
        setFood({ ...food, foodImages: foodImages } as Food);
      }
    },
    [food]
  );

  const saveChanges = useCallback(async () => {
    if (!food) {
      return;
    }
    if (
      !food.foodName ||
      !food.foodCusine ||
      !food.foodImages ||
      food.foodImages?.length === 0 ||
      !food.foodIng ||
      !food.foodRecepe ||
      !food.foodTime ||
      !food.foodType ||
      !food.foodPreparationTime ||
      food.foodAgeFrom === undefined ||
      !food.foodAgeTo ||
      food.foodAgeFrom > food.foodAgeTo ||
      food.foodAgeTo == 0
    ) {
      alert("Enter all mandatory fields");
      return;
    }

    const TagsList = food.tags?.map((s) => s.trim()) || [];

    if (food.key) {
      const foodRef = doc(db, "food", food.key);

      await updateDoc(foodRef, {
        ...food,
        lastUpdatedBy: getAuth().currentUser?.uid,
        Tags: TagsList,
      });

      const updatedList = foodList;
      const index = foodList.findIndex((x) => x.key === food.key);
      updatedList[index] = { ...food, tags: TagsList };
      setFoodList(updatedList);

      const updatedFilteredList = filteredFoodList;
      const filteredIndex = filteredFoodList?.findIndex(
        (x) => x.key === food.key
      );
      if (
        filteredIndex !== undefined &&
        filteredIndex >= 0 &&
        updatedFilteredList
      ) {
        updatedFilteredList[filteredIndex] = { ...food, tags: TagsList };
        setFilteredFoodList(updatedFilteredList);
      }

      alert("Updated Successfully");
    } else {
      const foodRef = await addDoc(collection(db, "food"), {
        ...food,
        tags: TagsList,
        lastUpdatedBy: getAuth().currentUser?.uid,
        createdAt: serverTimestamp(),
      });
      const updatedList = foodList;
      updatedList.push({ ...food, tags: TagsList, key: foodRef.id });
      setFoodList(updatedList);
      setFood({ ...food, tags: TagsList, key: foodRef.id });

      const updatedFilteredList = filteredFoodList;
      updatedFilteredList?.push({ ...food, tags: TagsList, key: foodRef.id });
      setFilteredFoodList(updatedFilteredList);
      alert("Created Successfully");
    }
  }, [db, filteredFoodList, food, foodList]);

  const listItem = useCallback(
    ({
      item,
      onPress,
    }: {
      item: Food;
      index: number;
      onPress?: (item: Food) => void;
    }) => {
      return (
        <TouchableOpacity
          style={{
            ...Layout.listItemSize,
            marginLeft: 5,
            flexDirection: "row",
            marginTop: 5,
            maxWidth: Layout.window.height / 2,
          }}
          onPress={() => {
            if (onPress) {
              return onPress?.(item);
            }

            setFood(item);
          }}
        >
          <Image
            source={{ uri: item.foodImages[0] }}
            style={{ ...Layout.thumbnailSmall, borderRadius: 5 }}
          />

          <View style={{ padding: 5 }}>
            <Text style={[Styles.textTileTitle]}>{item.foodName}</Text>
            <View style={Styles.row}>
              <Text style={Styles.textSubTitle}>{item.foodCusine}</Text>
            </View>
          </View>
        </TouchableOpacity>
      );
    },
    []
  );

  const renderList = useCallback(() => {
    return (
      <FlatList
        data={filteredFoodList || foodList}
        style={{
          marginTop: 10,
          paddingLeft: 50,
          height: Layout.window.height * 0.75,
          //marginBottom: 100 + ((playingNow.isPlayingNow || playingNow.Title || playingNow.live) ? 50 : 0)
        }}
        ListHeaderComponent={
          <View>
            <View style={Styles.flexRowCenterSpaceAround}>
              <Text style={{ fontSize: 30 }}>{foodList?.length} Recipes</Text>
              <TouchableOpacity
                style={{
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  backgroundColor: Colors.primary,
                  borderRadius: 5,
                  width: 200,
                  alignSelf: "center",
                  marginVertical: 20,
                }}
                onPress={() =>
                  setFood({
                    foodCusine: FoodCusine.INDIAN,
                    foodImages: [],
                    foodIng: "",
                    foodName: "",
                    foodRecepe: "",
                    foodTime: [],
                    foodType: undefined,
                    tags: [],
                    foodPreparationTime: "",
                    foodStatus: true,
                    foodAgeFrom: 0,
                    foodAgeTo: 0,
                  } as Food)
                }
              >
                <Text style={{ fontSize: 20, textAlign: "center" }}>
                  Add New Recipe
                </Text>
              </TouchableOpacity>
            </View>
            <TextInput
              value={searchText}
              label={"Search"}
              onChangeText={setSearchText}
            />
          </View>
        }
        keyExtractor={(item) => item.key || ""}
        renderItem={listItem}
      />
    );
  }, [filteredFoodList, listItem, foodList, searchText]);

  const renderDetails = useCallback(() => {
    return (
      <View
        style={{
          width: Layout.window.width / 2,
          borderWidth: 2,
          borderColor: Colors.primary,
          marginLeft: 10,
        }}
      >
        {!food && (
          <View style={styles.container}>
            <Text style={{ fontSize: 30 }}>
              Select a Recipe to view the details
            </Text>
          </View>
        )}
        {food && (
          <View>
            <FlatList
              data={food.foodImages}
              horizontal
              renderItem={({ item, index }) => (
                <TouchableOpacity
                  style={{
                    marginLeft: 20,
                    marginTop: 30,
                    marginBottom: 20,
                    alignItems: "center",
                  }}
                  onPress={() => pickImage(index)}
                >
                  <Image
                    source={{ uri: item }}
                    style={{ width: 100, height: 100 }}
                  />
                </TouchableOpacity>
              )}
              keyExtractor={(item) => item}
              ListFooterComponent={() => (
                <TouchableOpacity
                  style={{
                    marginLeft: 20,
                    marginTop: 30,
                    marginBottom: 20,
                    alignItems: "center",
                  }}
                  onPress={() => pickImage()}
                >
                  <FontAwesome
                    name="upload"
                    color={Colors.primary}
                    size={100}
                  />
                  <Text>Upload Image</Text>
                </TouchableOpacity>
              )}
            />
          </View>
        )}
        {food && (
          <View>
            <TextInput
              value={food.foodName}
              label={"Name"}
              onChangeText={(value: string) => {
                setFood({ ...food, foodName: value });
              }}
            />
            <TextInput
              value={food.foodPreparationTime || ""}
              label={"Food Preparation Time (in minutes)"}
              onChangeText={(value: string) => {
                setFood({ ...food, foodPreparationTime: value });
              }}
            />
            <View style={Styles.flexRowCenterSpaceBetween}>
              <TextInput
                value={food.foodAgeFrom === undefined ? "" : food.foodAgeFrom}
                label={"From Months"}
                onChangeText={(value: string) => {
                  setFood({
                    ...food,
                    foodAgeFrom: value ? parseInt(value) : 0,
                  });
                }}
              />
              <TextInput
                value={food.foodAgeTo === undefined ? "" : food.foodAgeTo}
                label={"To Months"}
                onChangeText={(value: string) => {
                  setFood({
                    ...food,
                    foodAgeTo: value ? parseInt(value) : 0,
                  });
                }}
              />
            </View>
            <TextInput
              value={food.foodIng}
              label={"Ingredients"}
              onChangeText={(value: string) => {
                setFood({ ...food, foodIng: value });
              }}
              multiline
            />

            <TextInput
              value={food.foodRecepe}
              label={"Recipe"}
              onChangeText={(value: string) => {
                setFood({ ...food, foodRecepe: value });
              }}
              multiline
            />

            <MultiSelectInput
              label="Type"
              options={[FoodType.VEG, FoodType.NON_VEG, FoodType.EGG]}
              values={[food.foodType]}
              singleSelect
              onUpdate={(values: string[]) => {
                setFood({ ...food, foodType: values[0] as FoodType });
              }}
            />
            <MultiSelectInput
              label="Cusine"
              options={[FoodCusine.INDIAN, FoodCusine.INTERNATIONAL]}
              values={[food.foodCusine]}
              singleSelect
              onUpdate={(values: string[]) => {
                setFood({ ...food, foodCusine: values[0] as FoodCusine });
              }}
            />
            <MultiSelectInput
              label="Time"
              options={[
                FoodTime.BREAKFAST,
                FoodTime.LUNCH,
                FoodTime.DINNER,
                FoodTime.SNACK,
              ]}
              values={food.foodTime || []}
              onUpdate={(values: string[]) => {
                setFood({ ...food, foodTime: values });
              }}
            />

            <TextInput
              value={food?.tags?.toString()}
              label={"Tags"}
              onChangeText={(value: string) => {
                setFood({ ...food, tags: value.split(",") });
              }}
            />

            <TouchableOpacity
              style={{
                paddingHorizontal: 10,
                paddingVertical: 5,
                backgroundColor: Colors.primary,
                borderRadius: 5,
                width: 150,
                alignSelf: "center",
                marginVertical: 20,
              }}
              onPress={saveChanges}
            >
              <Text style={{ fontSize: 20 }}>Save Changes</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  }, [food, pickImage, saveChanges]);
  useEffect(() => {
    async function fetchMyAPI() {
      setIsLoading(true);
      const q = query(collection(db, "food"), orderBy("foodName"));
      const podcasts: Food[] = [];

      const querySnapshot = await getDocs(q);
      for (const [index, doc] of querySnapshot.docs.entries()) {
        const _food = doc.data() as Food;
        _food.key = doc.id;
        if (!_food.foodImages[0]) {
          _food.foodImages[0] = getDefaultThumbnail(index);
        }
        podcasts.push(_food);
      }
      setFoodList(podcasts);
      setIsLoading(false);
    }

    fetchMyAPI();
  }, [db]);

  return (
    <Provider>
      <ScrollView
        contentContainerStyle={[
          Styles.container,
          Styles.paddingTop0,
          { marginTop: 0 },
        ]}
      >
        <View style={{ alignItems: "center", paddingHorizontal: 50 }}>
          {isLoading ? (
            <View style={{}}>
              <ActivityIndicator
                isLoading
                size="small"
                color={Colors[theme].primary}
              />
            </View>
          ) : foodList?.length ? (
            <View style={{ flexDirection: "row" }}>
              {renderList()}
              {renderDetails()}
            </View>
          ) : (
            <Text>No Recipes Found</Text>
          )}
        </View>
      </ScrollView>
    </Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
