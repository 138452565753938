import React, { Component } from "react";

import { FontAwesome } from "@expo/vector-icons";
import { StatusBar } from "expo-status-bar";
import {
  Animated,
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import { Bullet1, Bullet2, Header } from "../components/StyledText";
import Colors from "../constants/Colors";

export default class TnC extends Component {
  state = {
    fadeAnim: new Animated.Value(0),
    animated: new Animated.Value(0),
  };

  componentDidMount() {
    setTimeout(() => {
      //Wait for images to load before printing
      this.fadeIn();
    }, 1000);

    const duration = 5000;
    Animated.loop(
      Animated.sequence([
        Animated.timing(this.state.animated, {
          toValue: 50,
          duration: duration,
          useNativeDriver: true,
        }),
        Animated.timing(this.state.animated, {
          toValue: 0,
          duration: duration,
          useNativeDriver: true,
        }),
      ])
    ).start();
  }

  fadeIn = () => {
    // Will change fadeAnim value to 1 in 5 seconds
    Animated.timing(this.state.fadeAnim, {
      toValue: 1,
      duration: 3000,
      useNativeDriver: false,
    }).start();
  };

  fadeOut = () => {
    // Will change fadeAnim value to 0 in 3 seconds
    Animated.timing(this.state.fadeAnim, {
      toValue: 0,
      duration: 3000,
      useNativeDriver: false,
    }).start();
  };

  render() {
    return (
      <ScrollView contentContainerStyle={styles.container}>
        <View style={{ alignSelf: "stretch" }}>
          <TouchableOpacity
            style={{ marginLeft: 10, padding: 10 }}
            onPress={() => this.props.navigation.navigate("MonkiTox")}
          >
            <FontAwesome name="home" color={Colors.coral} size={20} />
          </TouchableOpacity>
        </View>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Image
            source={require("../assets/images/icon.png")}
            style={{ width: 30, height: 30, marginRight: 10, borderRadius: 5 }}
          />
          <Text style={{ color: Colors.coral, fontSize: 25 }}>Monki Tox</Text>
        </View>
        <Text
          style={{
            textAlign: "center",
            color: Colors.grey,
            fontSize: 20,
            marginTop: 20,
          }}
        >
          Terms & Conditions
        </Text>
        <View style={{ flex: 1, alignSelf: "stretch", padding: 20 }}>
          <Header>General</Header>
          <Bullet1>
            Welcome to the Monki Tox Mobile Application(App). This App is owned
            and operated by Chokolad Pvt Ltd.
          </Bullet1>
          <Bullet1>
            We serve digital audio content tailored for kids. The content
            includes moral stories, knowledge sharing, mythology and sleep music
          </Bullet1>
          <Bullet1>
            For the purpose of these terms of use ("Terms of Use"), "We", "Us"
            and "Our" means the Company and "You", "Your" and "User" means any
            person who accesses or uses the Website/App or the Services.
            "Services" includes access to Our mobile application ("App"), any
            upgrades of the App, our call and SMS based services, databases,
            Interactive Voice Response services and all other services and
            functionalities that We provide through the App or Your mobile
            phone.
          </Bullet1>
          <Bullet1>
            Every effort has been made to ensure, that the information contained
            on the Site is correct, however We make no warranty as the accuracy,
            comprehensiveness, or correctness of any Service(s) or a Product(s)
            on the App and provide all Service(s) and Product(s) on an "as is"
            basis. By accessing or using this App or the Services, you agree to
            be bound by these Terms of Use and Our other policies made available
            on the App, including but not limited to the Privacy Policy
            available in the "More" menu available at the bottom right of the
            screen. If You do not wish to agree to these Policies, please
            refrain from using the App.
          </Bullet1>
          <Header>CHANGES TO THE TERMS OF USE</Header>
          <Bullet1>
            We reserve the right to modify these Terms of Use and Our Policies
            from time to time, by updating this document without prior
            notification to You. Your continuing to use the App or Services
            following the changes shall be construed as Your acceptance of all
            such changes.
          </Bullet1>
          <Header>ACCESS TO THE APP AND SERVICES</Header>
          <Bullet1>
            You may access the App and the Services only if You are 18 years of
            age or older and are legally capable of entering into a binding
            contract as per applicable law, including the Indian Contract Act,
            1872.
          </Bullet1>
          <Header>YOUR INFORMATION</Header>
          <Bullet1>
            You may use the Services through Our App. Upon downloading the App,
            you may provide Us with Your details including Your name, address,
            contact number, email address and Store details ("User
            Information"). We would not be sharing any information to external
            Agencies or users unless you authenticate us to do so via the App.
          </Bullet1>
          <Bullet1>
            After obtaining this information, (i) You may customize your
            prefereneces of the content we serve.
          </Bullet1>
          <Bullet1>
            Alternatively, you may provide Us with Your mobile phone number on
            the App. We will then contact You, collect Your User Information and
            follow the procedure described in Clauses above.
          </Bullet1>
          <Bullet1>
            By providing Us with Your User Information, you confirm that:
          </Bullet1>
          <Bullet2>
            The User Information provided by You is accurate and genuine;
          </Bullet2>
          <Bullet2>
            The mobile phone number provided by You belongs to you, has been
            validly acquired under applicable law, and You may be contacted on
            the number by way of calls or SMS messages by Us or Agency;
          </Bullet2>
          <Bullet2>
            You are interested in availing the Services provided by Us;
          </Bullet2>
          <Bullet2>
            We may contact You either electronically or over the telephone to
            understand Your interest in the Services and to fulfill Your demand;
            and
          </Bullet2>
          <Bullet2>
            You shall immediately notify Us of any un-authorized use of Your
            User Information or any other breach of these Terms of Use or
            security known to You.
          </Bullet2>
          <Bullet1>Further, you authorize Us to:</Bullet1>
          <Bullet2>
            Collect, process and store User Information and access information
            including the IP address, IMEI number and MAC address of the
            computer/device from where the App were accessed.
          </Bullet2>
          <Bullet2>
            Either directly, or through third parties, verify and confirm Your
            User Information.
          </Bullet2>
          <Bullet2>
            Send You promotional materials and/or special offers.
          </Bullet2>
          <Bullet2>
            Communicate with You by email or by phone or usually available means
            of communication.
          </Bullet2>
          <Bullet2>
            Contact You using Your User Information, including for marketing and
            promotional calls.
          </Bullet2>
          <Bullet2>
            Subject to the above, and Your compliance with these Terms of Use,
            we grant You a non-exclusive, revocable, limited privilege to access
            and use this App and the Services.
          </Bullet2>
          <Header>FEES</Header>
          <Bullet1>
            The use of the App and Services is free of cost. However, this no
            charge policy can be amended at Our discretion and We reserve the
            right to charge for any of the Services.
          </Bullet1>
          <Header>USE OF THE APP AND SERVICES</Header>
          <Bullet1>
            You agree to make use of the App and Services for their intended
            purpose in a Bona fide manner. You agree not to:
          </Bullet1>
          <Bullet2>
            print, distribute, share, download, duplicate or otherwise copy,
            delete, vary or amend or use any data or User Information of any
            User other than You;
          </Bullet2>
          <Bullet2>
            You are interested in availing the Services provided by Us;
          </Bullet2>
          <Bullet2>
            infringe on any intellectual property rights of any person and/or
            retain information in any computer system or otherwise with an
            intention to do so;
          </Bullet2>
          <Bullet2>
            attempt to gain unauthorized access to any portion or feature of the
            App, or any other systems or networks connected to the App or to any
            server, computer, network, or to any of the Services by hacking,
            password "mining" or any other illegitimate means;
          </Bullet2>
          <Bullet2>
            probe, scan or test the vulnerability of the App or Services or any
            network connected to the App or Services or breach the security or
            authentication measures on the App, or any network connected to the
            App or Services;
          </Bullet2>
          <Bullet2>
            use any automated systems to extract data from the App;
          </Bullet2>
          <Bullet2>
            make any inaccurate, false, unfair or defamatory statement(s) or
            comment(s) about Us or the brand name or domain name used by Us, any
            Provider or any User on the App;
          </Bullet2>
          <Bullet2>
            take any action that imposes an unreasonable or disproportionately
            large load on the infrastructure of the App or Our systems or
            networks, or any systems or networks connected to Us;
          </Bullet2>
          <Bullet2>
            use the App or its contents for any commercial purpose;
          </Bullet2>
          <Bullet2>
            make any speculative, false, or fraudulent transaction or any
            transaction in anticipation of demand;
          </Bullet2>
          <Bullet2>
            take any action that imposes, or may impose, in Our discretion, an
            unreasonable or disproportionately large load on Our infrastructure;
          </Bullet2>
          <Bullet2>
            take any action that imposes, or may impose, in Our discretion, an
            unreasonable or disproportionately large load on Our infrastructure;
          </Bullet2>
          <Bullet2>
            link to any portion of this Site for any purpose without Our express
            written permission;
          </Bullet2>
          <Bullet2>
            "frame", "mirror" or otherwise incorporate any part of this App into
            any other website/App without Our prior written authorization; or
          </Bullet2>
          <Bullet2>
            circumvent or manipulate the App, Services, registration process,
            fees, billing system, or attempt to do so.
          </Bullet2>
          <Bullet1>
            You further agree not to host, display, upload, modify, publish,
            transmit, update, share or otherwise make available on the App any
            information, that:
          </Bullet1>
          <Bullet2>
            contains content or other material protected by intellectual
            property laws unless You own or control the rights thereto or have
            received all necessary consents;
          </Bullet2>
          <Bullet2>
            defames, abuses, harasses, stalks, hurts religious or ethnic
            sentiments of, threatens or otherwise violates the legal rights of
            others;
          </Bullet2>
          <Bullet2>
            attempt to gain unauthorized access to any portion or feature of the
            App, or any other systems or networks connected to the App or to any
            server, computer, network, or to any of the Services by hacking,
            password "mining" or any other illegitimate means;
          </Bullet2>
          <Bullet2>
            is grossly harmful, harassing, blasphemous defamatory, obscene,
            pornographic, pedophilic, libelous, invasive of another's privacy,
            hateful, or racially, ethnically objectionable, disparaging,
            relating or encouraging money laundering or gambling, or otherwise
            unlawful in any manner whatever;
          </Bullet2>
          <Bullet2>harms minors in any way;</Bullet2>
          <Bullet2>violates any law for the time being in force;</Bullet2>
          <Bullet2>
            deceives or misleads the addressee about the origin of such messages
            or communicates any information which is grossly offensive or
            menacing in nature;
          </Bullet2>
          <Bullet2>abets or assists with impersonating another person;</Bullet2>
          <Bullet2>
            contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any computer resource;
          </Bullet2>
          <Bullet2>
            uses any other internet service to send or post spam to drive
            visitors to any other site hosted on or through the Company's
            systems, whether the messages were originated by You, under Your
            direction, or by or under the direction of a related or unrelated
            third party; or
          </Bullet2>
          <Bullet2>
            contains any content which is non-compliant with the Information
            Technology Act, 2000, Rules and regulations, guidelines made
            thereunder, including Rule 3 of The Information Technology
            (Intermediaries Guidelines) Rules, 2011, Terms of Use or Privacy
            Policy, as amended/re-enacted from time to time.
          </Bullet2>
          <Header>INTELLECTUAL PROPERTY</Header>
          <Bullet1>
            The intellectual property in the App, and in the material, content
            and information made available on the App including audio, graphics,
            images, photographs, logos, trademarks, the appearance, organization
            and layout of the App and the underlying software code belong to Us
            or Our licensors.
          </Bullet1>
          <Bullet1>
            You shall not copy, modify, alter, publish, broadcast, distribute,
            sell or transfer (whether in whole or in part) any such material.
            The information provided on the App and through the Services is for
            Your personal use only.
          </Bullet1>
          <Header>INDEMNITY</Header>
          <Bullet1>
            You agree to indemnify Us, Our owners, licensees, affiliates, group
            companies and their respective officers, directors, agents, and
            employees, on demand, against any claim, action, damage, loss,
            liability, cost, charge, expense or payment which We may pay,
            suffer, incur or are liable for, in relation to any act You do or
            cause to be done, in breach of the Terms of Use or Your violation of
            any law, rules or regulations.
          </Bullet1>
          <Header>FEEDBACK, REPUTATION AND REVIEWS</Header>
          <Bullet1>
            You agree to be fair, accurate and non disparaging while leaving
            comments, feedback, testimonials and reviews ("Feedback") on or
            about the App or the Services, you acknowledge that You transfer all
            rights in such Feedback to Us and that We will be free to use the
            same as We may find appropriate.
          </Bullet1>
          <Header>BREACH OF THESE TERMS OF USE</Header>
          <Bullet1>
            If You have, or We have reasonable grounds to believe that You have,
            violated these Terms of Use in any way, we can indefinitely suspend
            or terminate Your account/access to the App or Services at any time,
            forfeit any amounts paid by You and report such action to relevant
            authorities. We reserve the right to take recourse to all available
            remedies under applicable law in furtherance of the above.
          </Bullet1>
          <Header>TRANSACTIONS</Header>
          <Bullet1>
            The App is a platform which provides Users with digital content for
            kids. We do not support any transactions at this point in time but
            we may introduce such options later as we see a demand.
          </Bullet1>
          <Bullet1>
            Accordingly, we will not be liable for any loss or damage incurred
            as the result of any such transaction. While interacting with any
            Agency found through the App or through the Services, we strongly
            encourage You to exercise reasonable diligence as You would in
            traditional off-line channels and practice judgment and common sense
            before committing to any Transaction or exchange of information.
          </Bullet1>
          <Bullet1>
            Our employees, partners and other staff are not accountable for any
            loss, harm or damage that occurs or may occur or arises from the
            usage of information from the Website/App by You. You are advised to
            use Your own discretion in such matters.
          </Bullet1>
          <Bullet1>
            All screening procedures, verification services and background
            checks, whether undertaken by Us or through third party consultants
            are performed on an "as is" and "as available" basis. By opting to
            avail of them You acknowledge that We will not be responsible for
            the quality or accuracy of the results of these procedures.
          </Bullet1>
          <Bullet1>
            Agencies may obtain personal information about You due to Your use
            of the App or Services, and such Agencies may use this information
            to harass or injure You. We do not approve of such acts, but by
            using the Services, you acknowledge that We are not responsible for
            the use of any personal information that You disclose or share with
            others through the use of the Services.
          </Bullet1>
          <Header>THIRD PARTY SITES</Header>
          <Bullet1>
            All third-party advertisements, hyperlinks or other redirection
            tools on the App which take You to content operated by third parties
            are not controlled by Us and do not form part of the App. We do not
            endorse and are not responsible for any content, advertising,
            products or other materials on or available from such sites. We are
            not liable for any loss or harm that occurs to You as a result of
            such sites.
          </Bullet1>
          <Header>DISCLAIMER AND LIMITATION OF LIABILITY</Header>
          <Bullet1>
            The App and Services are provided on an "as is" and "as available"
            basis without any representation or warranty, express or implied. We
            do not warrant that:
          </Bullet1>
          <Bullet2>
            The App or the Services will be constantly available, or available
            at all. We shall have no liability to You for any interruption or
            delay in access to the App or Services availed through it,
            irrespective of the cause;
          </Bullet2>
          <Bullet2>
            The information on the App or given through Services is complete,
            true, accurate or non-misleading;
          </Bullet2>
          <Bullet2>
            Any errors or defects in the App or Services will be corrected;
          </Bullet2>
          <Bullet2>
            That the App is secure or free of viruses, Trojans or other malware;
            or
          </Bullet2>
          <Bullet2>
            The contents of the App or the Services do not infringe any
            intellectual property rights.
          </Bullet2>
          <Bullet1>
            In no event shall We be liable for any direct, special, punitive,
            incidental, indirect or consequential damages including but not
            limited to any loss of profits, revenue, business or data of any
            kind in connection with these Terms of Use, even if You have been
            informed in advance of the possibility of such damages.
          </Bullet1>
          <Header>PRIVACY POLICY</Header>
          <Bullet1>
            Any personal information You supply to Us when You use this App, or
            the Services will be used in accordance with Our Privacy Policy
            below:
          </Bullet1>
          <Bullet2>
            In the course of registering for and availing various services We
            provide from time to time through the App ("Services") You may be
            required to give Your name, address, contact number, email address
            and your User Information.{" "}
          </Bullet2>
          <Bullet2>
            By using the App and registering yourself You authorize Us, our
            affiliates & Our associate partners to contact You via email or
            phone call or SMS and offer You their services for the product You
            have opted for, imparting product knowledge or offer promotional
            offers, as well as for web aggregation.{" "}
          </Bullet2>
          <Bullet2>
            You authorize Us to contact You for a period of 90 days from Your
            registration with Us irrespective of having registered under DND or
            DNC or NCPR services.{" "}
          </Bullet2>
          <Bullet2>
            Additionally, by registration You authorize Us to send SMS/email
            alerts to You for Your login details and any other service
            requirements or advertising messages/emails from Us.{" "}
          </Bullet2>
          <Bullet2>
            Further when we call you for providing details, you authorize us to
            record the conversation for Quality and Training purposes. You
            acknowledge that You are disclosing User Information voluntarily.{" "}
          </Bullet2>
          <Bullet2>
            Prior to the completion of any registration process on the App or
            prior to availing of any Services offered on Our website if You wish
            not to disclose any Personal Information You may refrain from doing
            so; however if You don't provide information that is requested, it
            is possible that the registration process would be incomplete and/or
            You may not be able to avail our Services.
          </Bullet2>
          <Bullet2>
            We are committed to protecting the privacy and confidentiality of
            all User Information that You may share as a user of Our App. In
            furtherance of the confidentiality with which We treat User
            Information We have put in place appropriate physical, electronic,
            and managerial procedures to safeguard and secure the information We
            collect online.
          </Bullet2>
          <Bullet2>
            To protect Your privacy and security, we will also take reasonable
            steps to verify Your identity before granting access or making
            corrections. This policy does not apply to the practices of
            organizations that We do not own or to people that We do not employ
            or manage. We may use third-party advertising companies and/or ad
            agencies to serve ads when You visit Our App. These companies may
            use information (excluding User Information) about Your visits to
            this and other Web sites in order to provide advertisements on this
            site and other sites about goods and services that may be of
            interest to You. There might be affiliates or other sites linked to
            Our App. Personal information that You provide to those sites are
            not Our property. The affiliated sites may have different privacy
            practices and We encourage You to read their privacy policies.
          </Bullet2>
          <Bullet2>
            We will not sell or rent User Information to anyone other than as
            specifically noted herein. We will share User Information if We have
            Your consent or deemed consent to do so or if We are compelled by
            law (including court orders) to do so.
          </Bullet2>
          <Bullet2>
            Not withstanding anything mentioned above, we reserve the right to
            contact You via any mechanisms, not limited to email, SMS, or phone
            calls, to solicit feedback of Your experience and to provide any
            additional services that You would be eligible for, either as a
            customer or as a visitor. We are not liable for any damages
            occurring to You as part of feedback solicitation and additional
            service provision.
          </Bullet2>
          {/* <Bullet2>Your have privacy controls on the Profile menu in the App to control what your customers see. You can choose to not share your phone number, email address and physical address as needed. You can also unlist your store anytime by marking it private in the Profile menu.</Bullet2> */}
          <Bullet2>
            We reserve the right to amend this policy from time to time, at Our
            discretion. Any updates will be posted on the App. If You have
            questions or concerns about Our Privacy Policy or any other
            policies, please email us at admin@monkitox.com. We reserve the
            right to amend this policy from time to time, at Our discretion. Any
            updates will be posted on the App. If You have questions or concerns
            about Our Privacy Policy or any other policies, please email us at
            admin@monkitox.com
          </Bullet2>
          <Header>MISCELLANEOUS</Header>
          <Bullet1>Severability and Waiver</Bullet1>
          <Bullet2>
            If any provisions of these Terms of Use are found to be invalid by
            any court having competent jurisdiction, the invalidity of such
            provision shall not affect the validity of the remaining provisions
            of these Terms of Use, which shall remain in full force and effect.
            No waiver of any term of these Terms of Use shall be deemed a
            further or continuing waiver of such term or any other term.
          </Bullet2>
          <Bullet1>Relationship between the Company and its Users</Bullet1>
          <Bullet2>
            Use of the App does not create any association, partnership, venture
            or relationship of principal and agent, master and servant or
            employer and employee between the User and the Company. Further,
            there is no relationship of principal and agent, master and servant
            or employer and employee between the Agency and the Company.
          </Bullet2>
          <Bullet1>Applicable Law and Dispute Resolution</Bullet1>
          <Bullet2>
            These Terms of Use shall be governed by and interpreted and
            construed in accordance with the laws of India. Any disputes
            pertaining to the App shall be subject to the exclusive jurisdiction
            of the appropriate courts in Telangana.
          </Bullet2>
          <Header>CONTACT US</Header>
          <Bullet1>
            Please contact Us for any questions or comments (including all
            inquiries related to copyright infringement) regarding this App.
          </Bullet1>
          <Bullet1>
            Should You wish to terminate Your account for any reason, you may do
            so by sending an email with the subject line "Termination" to the
            following email address:
          </Bullet1>
          <Text
            style={{
              textAlign: "center",
              fontSize: 20,
              fontWeight: "bold",
              color: Colors.coral,
            }}
          >
            admin@monkitox.com
          </Text>
          <Bullet1>
            This document is an electronic record under the Information
            Technology Act, 2000 and rules there under. This electronic record
            is generated by a computer system and does not require any physical
            or digital signatures.
          </Bullet1>
          <Bullet1>
            This document is published in accordance with the provisions of Rule
            3(1) of the information Technology (Intermediaries Guidelines)
            Rules, 2011 that require publishing the rules and regulations,
            Privacy Policy and Terms of Use for access to or usage of this App.
          </Bullet1>
        </View>

        <StatusBar style="auto" />
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "black",
    alignItems: "center",
    paddingTop: 20,
  },
  fadingContainer: {
    padding: 20,
    backgroundColor: "black",
    alignItems: "center",
  },
});
