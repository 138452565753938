/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
/* eslint-disable react-native/no-inline-styles */
window.Buffer = window.Buffer || require("buffer").Buffer;

import {
  collection,
  getDocs,
  getFirestore,
  orderBy,
  query,
} from "firebase/firestore";
import React, { useCallback, useEffect, useState } from "react";
import {
  FlatList,
  Image,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { Provider } from "react-native-paper";
import { ActivityIndicator, Text, View } from "../components/Themed";
import Colors from "../constants/Colors";
import Layout from "../constants/Layout";
import Styles from "../constants/Styles";
import { RootTabScreenProps, Vendor } from "../types";
import { getDefaultThumbnail } from "../utils/helper";
//const analytics = getAnalytics(app);
import SingleImage from "../assets/images/icon.png";
import ListImage from "../assets/images/icon_round.png";
import { useFocusEffect } from "@react-navigation/native";

export default function VendorList({
  navigation,
  route,
}: RootTabScreenProps<"Discover">) {
  const db = getFirestore();
  const [Vendor, setVendor] = useState<Vendor>();
  const [isLoading, setIsLoading] = useState(true);
  const [VendorList, setVendorList] = useState([]);

  const fetchMyAPI = useCallback(async () => {
    setIsLoading(true);
    const q = query(collection(db, "Vendors"), orderBy("Name"));
    const vendors: any = [];
    const querySnapshot = await getDocs(q);

    for (const [index, doc] of querySnapshot.docs.entries()) {
      const _vendor = doc.data() as any;
      _vendor.key = doc.id;
      if (!_vendor.Thumbnail) {
        _vendor.Thumbnail = getDefaultThumbnail(index);
      }
      if (Vendor?.key === _vendor.key) {
        setVendor(_vendor);
      }
      vendors.push(_vendor);
    }
    setVendorList(vendors);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchMyAPI();
    navigation.addListener("focus", () => {
      console.warn("VendorList Screen Focused");
      fetchMyAPI();
      setVendor(undefined);
    });
  }, []);

  const listItem = useCallback(
    ({
      item,
      onPress,
    }: {
      item: Vendor;
      index: number;
      onPress?: (item: Vendor) => void;
    }) => {
      return (
        <>
          <TouchableOpacity
            style={{
              ...Layout.listItemSize,
              marginLeft: 5,
              flexDirection: "row",
              marginTop: 5,
              maxWidth: Layout.window.height / 2,
            }}
            onPress={() => {
              if (onPress) {
                return onPress?.(item);
              }
              setVendor(item);
            }}
          >
            <Image
              source={{ uri: item.Logo || ListImage }}
              style={{ ...Layout.thumbnailSmall, borderRadius: 5 }}
            />

            <View style={{ padding: 5 }}>
              <Text style={[Styles.textTileTitle]}>{item.Name}</Text>
              <View style={Styles.row}>
                <Text style={Styles.textSubTitle}>{item.AddressLine1}</Text>
              </View>
            </View>
          </TouchableOpacity>
          <hr style={{ width: "100%", height: 2, color: Colors.primary }} />
        </>
      );
    },
    []
  );

  const renderList = useCallback(() => {
    return (
      <FlatList
        data={VendorList}
        style={{
          marginTop: 10,
          paddingLeft: 50,
          height: Layout.window.height * 0.75,
        }}
        ListHeaderComponent={
          <View
            style={{
              height: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <TouchableOpacity>
              <Text style={{ fontSize: 20, color: Colors.primary }}>
                {VendorList.length} Vendors
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                paddingHorizontal: 10,
                paddingVertical: 5,
                backgroundColor: Colors.primary,
                borderRadius: 5,
                width: 200,
                alignSelf: "center",
                marginVertical: 20,
              }}
              onPress={() => navigation.navigate("Onboarding")}
            >
              <Text style={{ fontSize: 20, textAlign: "center" }}>
                Add New Vendor
              </Text>
            </TouchableOpacity>
          </View>
        }
        keyExtractor={(item) => item.key || ""}
        renderItem={listItem}
      />
    );
  }, [listItem, VendorList]);

  const renderDetails = useCallback(() => {
    return (
      <View
        style={{
          width: Layout.window.width / 2,
          borderWidth: 2,
          borderColor: Colors.primary,
          marginLeft: 10,
        }}
      >
        {!Vendor && (
          <View style={styles.container}>
            <Text style={{ fontSize: 30 }}>
              Select a Vendor to view the details
            </Text>
          </View>
        )}
        {Vendor && (
          <View style={{ height: "100%", justifyContent: "space-around" }}>
            <View
              style={{
                flexDirection: "row",
                marginLeft: 20,
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <View style={{ marginRight: 10 }}>
                <TouchableOpacity style={{ alignItems: "center" }}>
                  <Image
                    source={{ uri: Vendor.Logo || SingleImage }}
                    style={{ width: 100, height: 100, borderRadius: 5 }}
                  />
                </TouchableOpacity>
              </View>
              <View
                style={{ flexDirection: "column", justifyContent: "center" }}
              >
                <Text style={styles.label}>Name</Text>
                <Text style={{ color: "white", fontSize: 18 }}>
                  {Vendor?.Name}
                </Text>
              </View>
            </View>
            <View style={{ flexDirection: "row", marginBottom: 10 }}>
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  marginRight: 10,
                  marginLeft: 20,
                  marginTop: 10,
                }}
              >
                <Text style={styles.label}>Phone</Text>
                <Text style={{ color: "white", fontSize: 18 }}>
                  {Vendor?.Phone}
                </Text>
              </View>
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  marginRight: 10,
                  marginLeft: 20,
                  marginTop: 10,
                }}
              >
                <Text style={styles.label}>Address</Text>
                <Text style={{ color: "white", fontSize: 18 }}>
                  {Vendor?.AddressLine1}
                </Text>
              </View>
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  marginRight: 10,
                  marginLeft: 20,
                  marginTop: 10,
                }}
              >
                <Text style={styles.label}>City</Text>
                <Text style={{ color: "white", fontSize: 18 }}>
                  {Vendor?.City}
                </Text>
              </View>
            </View>
            <View style={{ flexDirection: "row", marginBottom: 10 }}>
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  marginRight: 10,
                  marginLeft: 20,
                  marginTop: 10,
                }}
              >
                <Text style={styles.label}>State</Text>
                <Text style={{ color: "white", fontSize: 18 }}>
                  {Vendor?.State}
                </Text>
              </View>
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  marginRight: 10,
                  marginLeft: 20,
                  marginTop: 10,
                }}
              >
                <Text style={styles.label}>Country</Text>
                <Text style={{ color: "white", fontSize: 18 }}>
                  {Vendor?.Country}
                </Text>
              </View>
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  marginRight: 10,
                  marginLeft: 20,
                  marginTop: 10,
                }}
              >
                <Text style={styles.label}>Postal</Text>
                <Text style={{ color: "white", fontSize: 18 }}>
                  {Vendor?.Postal}
                </Text>
              </View>
            </View>
            <View style={{ flexDirection: "row", marginBottom: 10 }}>
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  marginRight: 10,
                  marginLeft: 20,
                  marginTop: 10,
                }}
              >
                <Text style={styles.label}>Commission %</Text>
                <Text style={{ color: "white", fontSize: 18 }}>
                  {Vendor?.Commission}
                </Text>
              </View>
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  marginRight: 10,
                  marginLeft: 20,
                  marginTop: 10,
                }}
              >
                <Text style={styles.label}>Status</Text>
                <Text style={{ color: "white", fontSize: 18 }}>
                  {`${Vendor?.Status}`}
                </Text>
              </View>
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  marginRight: 10,
                  marginLeft: 20,
                  marginTop: 10,
                }}
              >
                <Text style={styles.label}>International Shipping</Text>
                <Text style={{ color: "white", fontSize: 18 }}>
                  {`${Vendor?.InternationalShipping}`}
                </Text>
              </View>
            </View>

            <TouchableOpacity
              style={{
                paddingHorizontal: 10,
                paddingVertical: 5,
                backgroundColor: Colors.primary,
                borderRadius: 5,
                alignSelf: "flex-end",
                marginVertical: 10,
                marginRight: 20,
              }}
              onPress={() =>
                navigation.navigate("Onboarding", { value: Vendor })
              }
            >
              <Text style={{ fontSize: 20 }}>Edit</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  }, [Vendor, VendorList]);
  return (
    <Provider>
      <ScrollView
        contentContainerStyle={[
          Styles.container,
          Styles.paddingTop0,
          { marginTop: 0 },
        ]}
      >
        <View
          style={{ alignItems: "center", paddingHorizontal: 50, marginTop: 20 }}
        >
          {isLoading ? (
            <View>
              {" "}
              <ActivityIndicator isLoading size="large" color={"red"} />
              <Text>Vendors Ready to Load</Text>
            </View>
          ) : VendorList?.length ? (
            <View style={{ flexDirection: "row" }}>
              {renderList()}
              {renderDetails()}
            </View>
          ) : (
            <Text>No Vendor Found</Text>
          )}
        </View>
      </ScrollView>
    </Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    backgroundColor: "black",
    pading: 25,
    width: Layout.window.width * 0.5,
    height: Layout.window.height * 0.7,
    alignSelf: "center",
    borderWidth: 5,
    borderColor: Colors.primary,
    borderRadius: 10,
  },
  label: {
    fontSize: 14,
    marginBottom: 5,
    color: Colors.primary, // Adjust label color as needed
  },
});
