import React from "react";

// import { TextInput as DefaultTextInput } from "react-native";
import { Input as DefaultTextInput, Textarea } from "@chakra-ui/react";

import { View, Text } from "./Themed";
import Colors from "../constants/Colors";

type props = {
  value: string;
  label: string;
  onChangeText: any;
  multiline?: boolean;
};

export default function TextInput({
  value,
  label,
  onChangeText,
  multiline,
}: props) {
  return (
    <View
      style={{
        padding: 10,
        marginLeft: 10,
        marginRight: 20,
      }}
    >
      <Text style={{ color: Colors.grey, fontSize: 16 }}>{label}</Text>
      {multiline ? (
        <Textarea
          value={value}
          style={{ borderBottomColor: "grey", borderBottomWidth: 2 }}
          onChange={(e) => onChangeText(e.target.value)}
          noOfLines={4}
        />
      ) : (
        <DefaultTextInput
          value={value}
          style={{ borderBottomColor: "grey", borderBottomWidth: 2 }}
          onChange={(e) => onChangeText(e.target.value)}
        />
      )}
    </View>
  );
}
