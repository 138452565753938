/* eslint-disable prettier/prettier */
/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: ['http://localhost:19007*', 'https://www.monkitox.com', 'https://www.admin.monkitox.com', Linking.createURL('/')],
  config: {
    screens: {
      Root: {
        screens: {
          Discover: {
            screens: {
              DiscoverScreen: 'one',
            },
          },
        },
      },
      TnC: 'TnC',
      Admin: '*',
      Modal: 'modal',
    },
  },
};

export default linking;
