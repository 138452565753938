window.Buffer = window.Buffer || require("buffer").Buffer;

import { uuidv4 } from "@firebase/util";
import { manipulateAsync } from "expo-image-manipulator";
import * as ImagePicker from "expo-image-picker";
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ScrollView, TextInput, TouchableOpacity } from "react-native";
import { Provider } from "react-native-paper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormInput from "../components/FormInput";
import { ActivityIndicator, Text, View } from "../components/Themed";
import Styles from "../constants/Styles";
import { RootTabScreenProps, Vendor } from "../types";
import { getAuth } from "firebase/auth";
export default function OnboardingForm({
  navigation,
  route,
}: RootTabScreenProps<"Discover">) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isLogoUploading, setIsLogoUploading] = useState<boolean>(false);
  const [vendor, setVendor] = useState<Vendor>();
  const db = getFirestore();

  useEffect(() => {
    if (route?.params?.value) {
      console.warn("route?.params?.value", route?.params?.value);
      setValue("Name", route?.params?.value?.Name);
      setValue("Phone", route?.params?.value?.Phone);
      setValue("Email", route?.params?.value?.Email);
      setValue("Instagram", route?.params?.value?.Instagram);
      setValue("AddressLine1", route?.params?.value?.AddressLine1);
      setValue("City", route?.params?.value?.City);
      setValue("State", route?.params?.value?.State);
      setValue("Postal", route?.params?.value?.Postal);
      setValue("Country", route?.params?.value?.Country);
      setValue("Logo", route?.params?.value?.Logo);
      setValue("Commission", route?.params?.value?.Commission);
      setValue(
        "InternationalShipping",
        route?.params?.value?.InternationalShipping ? "Yes" : "No"
      );
      setValue("Status", route?.params?.value?.Status ? "Yes" : "No");

      setVendor(route?.params?.value);
    }
  }, [route]);

  const onSubmit = async (data: any) => {
    const internationalShipping = data.InternationalShipping === "Yes";
    const status = data.Status === "Yes";
    data.InternationalShipping = internationalShipping;
    data.Status = status;
    data.Email = data.Email || "";
    data.Instagram = data.Instagram || "";
    if (route?.params?.value) {
      try {
        console.warn(route?.params?.value?.key);
        const docRef = await updateDoc(
          doc(db, "Vendors", route?.params?.value.key),
          {
            ...data,
            lastUpdatedBy: getAuth().currentUser?.uid,
            timestamp: serverTimestamp(),
          }
        );
        toast("Vendor updated Successfully");
        setTimeout(() => {
          navigation.navigate("Vendors");
        }, 3000);
      } catch (error) {
        console.error("Error updating document:", error);
      }
    } else {
      try {
        const docRef = await addDoc(collection(db, "Vendors"), {
          ...data,
          lastUpdatedBy: getAuth().currentUser?.uid,
          timestamp: serverTimestamp(),
        });
        toast("Vendor Onboarding Successfully");
        setTimeout(() => {
          navigation.navigate("VendorList");
        }, 3000);
      } catch (error) {
        console.error("Error adding document:", error);
      }
    }
  };

  async function uploadImageAsync(uri: string) {
    // Why are we using XMLHttpRequest? See:
    // https://github.com/expo/expo/issues/2402#issuecomment-443726662
    setIsLogoUploading(true);
    const manipResult = await manipulateAsync(
      uri,
      [{ resize: { width: 250 } }],
      { compress: 1 }
    );
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        console.log(e);
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", manipResult.uri, true);
      xhr.send(null);
    });

    const fileRef = ref(getStorage(), uuidv4());
    await uploadBytes(fileRef, blob);

    // We're done with the blob, close and release it
    //blob.close();
    setIsLogoUploading(false);
    return await getDownloadURL(fileRef);
  }

  const pickImage = useCallback(async (index?: number) => {
    // No permissions request is necessary for launching the image library
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });

    console.log(result);

    if (!result.canceled) {
      const image_url = await uploadImageAsync(result.uri);
      console.log(image_url);
      setValue("Logo", image_url);
      if (image_url) {
        setVendor({ ...vendor, Logo: image_url });
      }
    }
  }, []);

  return (
    <Provider>
      <ToastContainer />
      <ScrollView
        contentContainerStyle={[
          Styles.container,
          Styles.paddingTop0,
          { marginTop: 0 },
        ]}
      >
        <View>
          <View style={Styles.onboardContainer}>
            <View style={Styles.onboardFormContainer}>
              <Text style={Styles.onboardTitle}>Onboarding Form</Text>
              <View style={[Styles.rowStyle, { marginTop: 0 }]}>
                <FormInput
                  label="Vendor Name"
                  control={control}
                  name="Name"
                  rules={{ required: "Name is required" }}
                  defaultValue=""
                  errors={errors}
                />
                <FormInput
                  label="Phone Number"
                  name="Phone"
                  control={control}
                  rules={{
                    required: "Phone is required",
                    minLength: {
                      value: 10,
                      message: "Phone must be at least 10 characters long",
                    },
                  }}
                  defaultValue=""
                  customOnChange={(text: string) => {
                    const numericText = text.replace(/[^0-9]/g, "");
                    const truncatedText = numericText.slice(0, 10);
                    setValue("Phone", truncatedText); // Update form value
                  }}
                  errors={errors}
                />
                <FormInput
                  label="Email"
                  name="Email"
                  control={control}
                  defaultValue=""
                  customOnChange={(text: string) => {
                    setValue("Email", text); // Update form value
                  }}
                  errors={errors}
                />
              </View>
              <View style={Styles.rowStyle}>
                <FormInput
                  label="Address"
                  control={control}
                  name="AddressLine1"
                  rules={{ required: "Address is required" }}
                  defaultValue=""
                  errors={errors}
                />
                <FormInput
                  label="City"
                  control={control}
                  name="City"
                  rules={{ required: "City is required" }}
                  defaultValue=""
                  errors={errors}
                />
                <FormInput
                  label="State"
                  control={control}
                  name="State"
                  rules={{ required: "State is required" }}
                  defaultValue=""
                  errors={errors}
                />
                <FormInput
                  label="Postal"
                  control={control}
                  name="Postal"
                  rules={{ required: "Postal is required" }}
                  defaultValue=""
                  customOnChange={(text: string) => {
                    const numericText = text.replace(/[^0-9]/g, "");
                    const truncatedText = numericText.slice(0, 6);
                    setValue("Postal", truncatedText); // Update form value
                  }}
                  errors={errors}
                />
              </View>
              <View style={Styles.rowStyle}>
                <FormInput
                  label="Country"
                  control={control}
                  name="Country"
                  rules={{ required: "Country is required" }}
                  defaultValue=""
                  errors={errors}
                />
                <View style={Styles.columnStyle}>
                  <Text style={Styles.onboardLabel}>Logo URL</Text>
                  <View
                    style={{
                      flexDirection: "row",
                    }}
                  >
                    <Controller
                      control={control}
                      render={({ field }: any) => (
                        <>
                          <TextInput
                            style={Styles.onboardFileInput}
                            placeholder="Logo URL"
                            placeholderTextColor="#777"
                            value={field.value}
                          />
                          <View style={Styles.onboardUploadIcon}>
                            {isLogoUploading ? (
                              <ActivityIndicator
                                isLoading
                                size="large"
                                color={"red"}
                              />
                            ) : (
                              <>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  onClick={() => pickImage()}
                                  style={{ cursor: "pointer" }}
                                >
                                  <path
                                    fill="currentColor"
                                    d="M11 16V7.85l-2.6 2.6L7 9l5-5l5 5l-1.4 1.45l-2.6-2.6V16zm-7 4v-5h2v3h12v-3h2v5z"
                                  />
                                </svg>
                                <input
                                  type="file"
                                  ref={fileInputRef}
                                  style={{ display: "none" }}
                                  onChange={() => pickImage()}
                                />
                              </>
                            )}
                          </View>
                        </>
                      )}
                      name="Logo"
                      rules={{ required: "Logo Url is required" }}
                      defaultValue={vendor?.Logo}
                    />
                  </View>
                  {errors.Logo && (
                    <Text style={Styles.onboardErrorText}>
                      {(errors as any).Logo.message}
                    </Text>
                  )}
                </View>
                <FormInput
                  label="Commission %"
                  control={control}
                  name="Commission"
                  rules={{ required: "Commission is required" }}
                  defaultValue=""
                  errors={errors}
                  customOnChange={(text: string) => {
                    const numericText = text.replace(/[^0-9]/g, "");
                    const truncatedText = numericText.slice(0, 4);
                    setValue("Commission", Number(truncatedText)); // Update form value
                  }}
                />
                <FormInput
                  label="Instagram"
                  name="Instagram"
                  control={control}
                  defaultValue=""
                  customOnChange={(text: string) => {
                    setValue("Instagram", text); // Update form value
                  }}
                  errors={errors}
                />
              </View>
              <View style={Styles.rowStyle}>
                <FormInput
                  label="International Shipping"
                  name="InternationalShipping"
                  control={control}
                  rules={{ required: "International Shipping is required" }}
                  defaultValue="No"
                  isSwitch={true}
                  switchLabels={{ true: "Yes", false: "No" }}
                  errors={errors}
                />
                <FormInput
                  label="Status"
                  name="Status"
                  control={control}
                  rules={{ required: "Status is required" }}
                  defaultValue="Yes"
                  isSwitch={true}
                  switchLabels={{ true: "Active", false: "In_Active" }}
                  errors={errors}
                />
                <View style={Styles.columnStyle}></View>
              </View>
              <View style={Styles.buttonContainer}>
                <TouchableOpacity
                  style={Styles.formButton}
                  onPress={() => navigation.navigate("VendorList")}
                >
                  <Text style={Styles.formButtonLabel}>Cancel</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={Styles.formButton}
                  onPress={handleSubmit(onSubmit)}
                >
                  <Text style={Styles.formButtonLabel}>Submit</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </Provider>
  );
}
