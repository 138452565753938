//import { FirebaseRecaptchaVerifierModal } from 'expo-firebase-recaptcha';
import React, { useCallback, useEffect, useRef, useState } from "react";

import { LinearGradient } from "expo-linear-gradient";
import { initializeApp } from "firebase/app";
import {
  PhoneAuthProvider,
  RecaptchaVerifier,
  getAuth,
  onAuthStateChanged,
  signInWithCredential,
} from "firebase/auth";
import { initializeFirestore } from "firebase/firestore";
import {
  Alert,
  Image,
  Keyboard,
  StyleSheet,
  TextInput,
  TouchableOpacity,
} from "react-native";

import { ActivityIndicator, Text, View } from "../components/Themed";

import Colors from "../constants/Colors";
import Contents from "../constants/Contents";
import Styles from "../constants/Styles";
import useColorScheme from "../hooks/useColorScheme";
import { RootTabScreenProps } from "../types";

const firebaseConfig = {
  apiKey: "AIzaSyDzPwl8R8xY9XcTpjFt0Ri4LVGJI4tq01I",
  authDomain: "monkitox-podcasts.firebaseapp.com",
  projectId: "monkitox-podcasts",
  storageBucket: "monkitox-podcasts.appspot.com",
  messagingSenderId: "842438460443",
  appId: "1:842438460443:web:dfc6903f1b2a12ace3a4be",
  measurementId: "G-N96XH7FY05",
};

//const analytics = getAnalytics(app);

const app = initializeApp(firebaseConfig);
const db = initializeFirestore(app, {
  experimentalForceLongPolling: true,
});
const auth = getAuth(app);

export default function Admin({ navigation }: RootTabScreenProps<"Discover">) {
  const theme = useColorScheme();

  const [isShowPhoneNumber, setisShowPhoneNumber] = useState<boolean>(true);
  const [phoneNumber, setphoneNumber] = useState<string>();
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [otp, setotp] = useState<string>();

  const [verificationId, setVerificationId] = useState();

  const onClick = useCallback(async () => {
    if (phoneNumber && phoneNumber.length === 10) {
      setisLoading(true);
      Keyboard.dismiss();
      console.log("PhoneNumberScreen", phoneNumber);

      try {
        const phoneProvider = new PhoneAuthProvider(auth);
        const verify = new RecaptchaVerifier(auth, "recaptcha-container", {
          size: "invisible",
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            //onSignInSubmit();
          },
        });
        const verificationId = await phoneProvider.verifyPhoneNumber(
          "+91" + phoneNumber,
          verify
        );
        setVerificationId(verificationId);

        setisShowPhoneNumber(false);
        setisLoading(false);
      } catch (err) {
        alert(err.message);

        setisLoading(false);
      }
    } else {
      Alert.alert(
        "Invalid Phone Number",
        "Please enter your 10 digit phone number",
        [{ text: "Got It", onPress: () => null }]
      );
    }
  }, [phoneNumber]);

  const verifyOTP = useCallback(async () => {
    setisLoading(true);

    try {
      const credential = PhoneAuthProvider.credential(verificationId, otp);
      await signInWithCredential(auth, credential);

      navigation.replace("AdminStack");
      alert("Authenticated");
    } catch (err) {
      Alert.alert("OopS!", `Error: ${err.message}`, [
        { text: "Got It", onPress: () => null },
      ]);
      setisLoading(false);
    }
  }, [verificationId, otp, navigation]);

  useEffect(() => {
    if (otp?.length === 6) {
      Keyboard.dismiss();
      verifyOTP();
    }
  }, [otp, verifyOTP]);

  onAuthStateChanged(auth, (user) => {
    if (user != null) {
      console.log("We are authenticated now!");
      navigation.replace("AdminStack");
    }

    // Do other things
  });

  return (
    <View style={[Styles.container]}>
      <View
        style={{
          paddingTop: 35,
          paddingHorizontal: 10,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Image
          source={require("../assets/images/icon.png")}
          style={{
            height: 60,
            width: 60,
            borderRadius: 10,
          }}
        />
        <View style={{ marginLeft: 10 }}>
          <Text style={{ fontSize: 40 }} numberOfLines={1}>
            {Contents.title}
          </Text>
          <Text style={{ color: Colors.coral, fontStyle: "italic" }}>
            {Contents.subTitle}
          </Text>
        </View>
      </View>
      <View style={[styles.container]}>
        <Text style={{ fontSize: 30, marginBottom: 10 }}>Admin Dashboard</Text>
        <div id="recaptcha-container" />
        <View style={{ alignItems: "center" }}>
          {!isShowPhoneNumber && (
            <View>
              <Text
                style={{
                  justifyContent: "flex-start",
                  color: Colors[theme].primary,
                }}
              >
                {"OTP sent to " + phoneNumber}
              </Text>
              <TouchableOpacity
                style={{ padding: 10 }}
                onPress={() => setisShowPhoneNumber(true)}
              >
                <Text
                  style={{
                    color: Colors[theme].secondary,
                    textDecorationLine: "underline",
                  }}
                >
                  Change Phone Number
                </Text>
              </TouchableOpacity>
            </View>
          )}
          <View>
            <Text
              style={{
                justifyContent: "flex-start",
                color: Colors[theme].primary,
              }}
            >
              {isShowPhoneNumber ? "Phone Number" : "One-time Pin"}
            </Text>
            <View style={styles.inputContainer}>
              {isShowPhoneNumber ? (
                <TextInput
                  style={styles.inputs}
                  value={phoneNumber}
                  keyboardType="phone-pad"
                  placeholder="10 digit Ph Number"
                  placeholderTextColor={Colors.grey}
                  onChangeText={(phoneNumber) => setphoneNumber(phoneNumber)}
                />
              ) : (
                <View>
                  <TextInput
                    style={styles.inputs}
                    value={otp}
                    keyboardType="phone-pad"
                    placeholder="Enter OTP"
                    placeholderTextColor={Colors.grey}
                    onChangeText={(otp) => setotp(otp)}
                  />
                </View>
              )}
            </View>
          </View>
          {isLoading ? (
            <View style={{}}>
              <ActivityIndicator
                isLoading
                size="small"
                color={Colors[theme].primary}
              />
            </View>
          ) : (
            <TouchableOpacity
              style={[styles.buttonContainer, styles.sendButton]}
              onPress={() => (isShowPhoneNumber ? onClick() : verifyOTP())}
            >
              <Text style={styles.buttonText}>
                {isShowPhoneNumber ? "Send OTP" : "Verify OTP"}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  inputContainer: {
    borderBottomWidth: 1,
    width: 300,
    height: 50,
    marginBottom: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  inputs: {
    height: 45,
    marginLeft: 0,
    fontSize: 20,
    flex: 1,
  },
  buttonContainer: {
    height: 50,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
    width: 200,
    borderRadius: 10,
  },
  sendButton: {
    backgroundColor: Colors.primary,
  },
  buttonText: {
    color: "white",
    fontSize: 18,
  },
});
