/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
/* eslint-disable react-native/no-inline-styles */
window.Buffer = window.Buffer || require("buffer").Buffer;

import { FontAwesome } from "@expo/vector-icons";
import {
  collection,
  getDocs,
  getFirestore,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useCallback, useEffect, useState } from "react";
import {
  FlatList,
  Image,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import { Provider } from "react-native-paper";
import CheckBox from "../components/Checkbox";
import { ActivityIndicator, Text, View } from "../components/Themed";
import Colors from "../constants/Colors";
import Layout from "../constants/Layout";
import Styles from "../constants/Styles";
import { Product, RootTabScreenProps, Vendor } from "../types";
import { getDefaultThumbnail } from "../utils/helper";
import ProductForm from "./ProductForm";
import { Dropdown } from "react-native-element-dropdown";
//const analytics = getAnalytics(app);

export default function ProductList({
  navigation,
}: RootTabScreenProps<"Discover">) {
  const db = getFirestore();
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [isLoading, setIsLoading] = useState(false);
  const [productList, setProductList] = useState<Product[]>([]);
  const [vendorList, setVendorList] = useState<Vendor[]>([]);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [filterKey, setFilterKey] = useState<string>();
  const [isOpen, setIsOpen] = useState(false);
  const windowHeight = useWindowDimensions().height;
  const listHeight = windowHeight * 0.7;

  const fetchMyAPI = useCallback(async () => {
    setIsLoading(true);
    let q = query(collection(db, "Products"), orderBy("name"));
    if (filterKey) {
      q = query(q, where("vendor", "==", filterKey));
    }
    const products: any = [];
    const querySnapshot = await getDocs(q);
    for (const [index, doc] of querySnapshot.docs.entries()) {
      const _product = doc.data() as any;
      _product.key = doc.id;
      if (!_product.Thumbnail) {
        _product.Thumbnail = getDefaultThumbnail(index);
      }
      products.push(_product);
    }

    setProductList(products);
    setIsLoading(false);
  }, [db, filterKey]);

  // useEffect(() => {
  //   fetchMyAPI();
  // }, [db]);

  useEffect(() => {
    if (filterKey) {
      fetchMyAPI();
    }
  }, [filterKey]);

  useEffect(() => {
    const vendorData = query(collection(db, "Vendors"), orderBy("Name"));
    const vendors: any = [];
    getDocs(vendorData).then((vendorQuery) => {
      for (const [index, doc] of vendorQuery.docs.entries()) {
        const _vendor = doc.data() as any;
        _vendor.key = doc.id;
        if (!_vendor.Thumbnail) {
          _vendor.Thumbnail = getDefaultThumbnail(index);
        }
        vendors.push(_vendor);
      }
      setVendorList(vendors);
    });
  }, [db]);

  const renderList = useCallback(() => {
    return (
      <View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            overflow: "visible",
          }}
        >
          <Dropdown
            style={Styles.dropdown}
            data={vendorList}
            maxHeight={300}
            labelField="Name"
            valueField="key"
            placeholder="Select Vendor"
            value={vendorList.find((vendor) => vendor.key === filterKey)}
            onChange={(vendor) => {
              setFilterKey(vendor.key);
            }}
          />
          <TouchableOpacity
            style={{
              ...Layout.listItemSize,
              marginLeft: 5,
              flexDirection: "row",
              maxWidth: Layout.window.height / 2,
              alignItems: "center",
            }}
          >
            <Text style={{ fontSize: 20, color: Colors.primary }}>
              {productList.length} products
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              paddingHorizontal: 10,
              paddingVertical: 5,
              backgroundColor: Colors.primary,
              borderRadius: 5,
              width: 200,
              alignSelf: "center",
              marginVertical: 10,
            }}
            onPress={() => {
              setIsOpen(true);
              setSelectedProduct(undefined);
              // navigation.navigate("Product", { value: undefined });
            }}
          >
            <Text style={{ fontSize: 20, textAlign: "center" }}>
              Add New Product
            </Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={{
              marginHorizontal: 20,
              paddingHorizontal: 10,
              paddingVertical: 5,
              alignSelf: "center",
              marginVertical: 10,
            }}
            onPress={() => {
              fetchMyAPI();
              // navigation.navigate("Product", { value: undefined });
            }}
          >
            <FontAwesome name="refresh" size={20} color={Colors.primary} />
          </TouchableOpacity>

          <Text style={{ fontSize: 20, color: Colors.primary, marginLeft: 10 }}>
            In Stock
          </Text>
        </View>
        <View style={{ height: listHeight, zIndex: -1 }}>
          <FlatList
            data={productList}
            renderItem={({ item, index }) => {
              return (
                <View>
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      style={{
                        marginLeft: 5,
                        flexDirection: "row",
                        marginTop: 5,
                        width: 400,
                      }}
                      onPress={() => {
                        setSelectedProduct(item);
                        setIsOpen(true);
                      }}
                    >
                      <Image
                        source={{ uri: item?.images[0] }}
                        style={{
                          width: 100,
                          height: 100,
                          borderRadius: 5,
                          marginRight: 10,
                        }}
                      />

                      <View style={{ padding: 5 }}>
                        <Text
                          style={[
                            Styles.textTileTitle,
                            { color: Colors.primary },
                          ]}
                        >
                          {item.name}
                        </Text>
                        <View style={Styles.row}>
                          <Text
                            style={[Styles.textSubTitle, { maxWidth: 300 }]}
                            numberOfLines={3}
                          >
                            {item.description}
                          </Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{ flex: 1, alignItems: "flex-end" }}
                    >
                      <CheckBox isChecked={item?.inStock} />
                    </TouchableOpacity>
                  </View>
                  <hr
                    style={{ width: "100%", height: 2, color: Colors.primary }}
                  />
                </View>
              );
            }}
            ListFooterComponent={() => {
              return (
                <View style={{ height: 50 }}>
                  <ActivityIndicator isLoading={isLoading} />
                </View>
              );
            }}
          />
        </View>
      </View>
    );
  }, [productList, showFilterOptions, vendorList, isLoading]);

  return (
    <Provider>
      <ProductForm
        isVisble={isOpen}
        setIsVisible={setIsOpen}
        selectedProduct={selectedProduct}
        onUpdated={(product) => {
          setProductList((cv) => {
            const index = cv.findIndex((p) => p.key === product?.key);
            if (index === -1) {
              return [product, ...cv];
            }
            cv[index] = product;
            return [...cv];
          });
        }}
      />
      <ScrollView
        contentContainerStyle={[
          Styles.container,
          Styles.paddingTop0,
          { marginTop: 0, backgroundColor: Colors.white },
        ]}
      >
        <View
          style={{ alignItems: "center", paddingHorizontal: 50, marginTop: 20 }}
        >
          <View style={{ flexDirection: "row" }}>{renderList()}</View>
        </View>
      </ScrollView>
    </Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    pading: 25,
    width: Layout.window.width * 0.5,
    height: Layout.window.height * 0.7,
    alignSelf: "center",
    borderWidth: 5,
    borderColor: Colors.primary,
    borderRadius: 10,
  },
  label: {
    fontSize: 14,
    marginBottom: 5,
    color: Colors.primary, // Adjust label color as needed
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
  card: {
    borderRadius: 10,
    shadowColor: "#000",
    width: "100%",
    height: 150,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    padding: 20,
  },
});
