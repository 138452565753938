export default {
  title: "Monki Tox",
  subTitle: "The Indian Parenting Hub",
  defaultThumbnails: [
    "https://firebasestorage.googleapis.com/v0/b/monkitox-podcasts.appspot.com/o/Thumbnails%2Fpodcast1.jpeg?alt=media&token=c57543fe-548d-493b-9279-2b2413876b2c",
    "https://firebasestorage.googleapis.com/v0/b/monkitox-podcasts.appspot.com/o/Thumbnails%2Fpodcast2.jpg?alt=media&token=462a0eb2-7a52-41c8-8e29-4a0f40a7116e",
    "https://firebasestorage.googleapis.com/v0/b/monkitox-podcasts.appspot.com/o/Thumbnails%2Fpodcast3.jpg?alt=media&token=a5ebaedb-36f4-4dd4-8d63-ebfd70399e53",
    "https://firebasestorage.googleapis.com/v0/b/monkitox-podcasts.appspot.com/o/Thumbnails%2Fpodcast4.jpg?alt=media&token=2d3088ca-6410-4526-8c47-abaac1775559",
  ],
  languages: [
    "English",
    "Tamil",
    "Hindi",
    "Telugu",
    "Kannada",
    "Malayalam",
    "Marathi",
    "Odia",
    "Saurashtra",
    "Sanskrit",
  ],
};
