import React from "react";

import { AntDesign, FontAwesome5 } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native";

import { ActivityIndicator, View } from "./Themed";
import Colors from "../constants/Colors";
import Styles from "../constants/Styles";

type props = {
  name: string;
  color?: string;
  onButtonPressed: () => void;
  isPlayingNow?: boolean;
  size?: number;
  isAntDesign?: boolean;
  isLoading?: boolean;
};

export default function FontAwesomeButton(props: props) {
  let name = props.name;
  if (props.name === "play" || props.name === "play-circle") {
    if (props.isPlayingNow) {
      name = props.name === "play" ? "pause" : "pause-circle";
    }
  }
  if (props.isLoading) {
    return (
      //    <View style={[{ paddingVertical: 13 }, Styles.transparantBackground]}>
      <ActivityIndicator isLoading={true} />
      //    </View>
    );
  }
  return (
    <TouchableOpacity
      style={{ padding: 5 }}
      onPress={() => props.onButtonPressed()}
    >
      {props.isAntDesign ? (
        <AntDesign
          name={name}
          color={props.color || Colors.grey}
          size={props.size || 25}
        />
      ) : (
        <FontAwesome5
          name={name}
          color={props.color || Colors.grey}
          size={props.size || 25}
        />
      )}
    </TouchableOpacity>
  );
}
