/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
/* eslint-disable react-native/no-inline-styles */
window.Buffer = window.Buffer || require("buffer").Buffer;

import { Card } from "@chakra-ui/react";
import { getFirestore } from "firebase/firestore";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import React, { useCallback, useEffect, useState } from "react";
import {
  FlatList,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import { ChakraInput, Text, View } from "../components/Themed";
import Colors from "../constants/Colors";
import Layout from "../constants/Layout";
import Styles from "../constants/Styles";
import { RootTabScreenProps, User, Vendor } from "../types";
import UserDetails from "./UserDetails";
import moment from "moment";
import { DATE_FORMAT } from "../constants/Config";
import { Searchbar } from "react-native-paper";
import { set } from "react-hook-form";
//const analytics = getAnalytics(app);

export default function UserList({
  navigation,
}: RootTabScreenProps<"Discover">) {
  const db = getFirestore();
  const [selectedUser, setSelectedUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(true);
  const [userList, setUserList] = useState<User[]>([]);
  const [filteredUserList, setFilteredUserList] = useState<User[]>();
  const [searchText, setSearchText] = useState("");

  const [filterKey, setFilterKey] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const windowHeight = useWindowDimensions().height;
  const listHeight = windowHeight * 0.85;

  const fetchMyAPI = useCallback(async () => {
    setIsLoading(true);
    const functions = getFunctions();
    if (__DEV__) {
      connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    }
    const getUsers = httpsCallable(functions, "getUsers");
    getUsers().then((result) => {
      console.warn(result.data);
      setUserList(result.data);
    });

    setIsLoading(false);
  }, [db]);

  // useEffect(() => {
  //   fetchMyAPI();
  // }, [db]);

  useEffect(() => {
    fetchMyAPI();
  }, []);

  useEffect(() => {
    if (searchText === "") {
      setFilteredUserList(undefined);
    } else {
      setFilteredUserList(
        userList.filter((user) =>
          user.phone.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }
  }, [searchText]);

  return (
    <View>
      <UserDetails
        isVisble={isOpen}
        selectedUser={selectedUser}
        setIsVisible={setIsOpen}
      />
      <FlatList
        ListHeaderComponent={
          <ChakraInput
            placeholder="Search"
            value={searchText}
            onChangeText={(text) => setSearchText(text)}
            style={Styles.margin}
          />
        }
        style={{ height: listHeight }}
        showsVerticalScrollIndicator={true}
        data={filteredUserList || userList}
        keyExtractor={(item) => item.uid}
        renderItem={({ item }) => (
          <TouchableOpacity
            style={[Styles.margin, { width: 500 }]}
            onPress={() => {
              setSelectedUser(item);
              setIsOpen(true);
            }}
          >
            <Card p={3}>
              <Text style={Styles.textTitle}>{item.phone}</Text>
              <Text>{`Created On: ${moment(item.createdAt).format(
                DATE_FORMAT
              )}`}</Text>
              <Text>{`Last Sign In: ${moment(item.lastSignIn).format(
                DATE_FORMAT
              )}`}</Text>
              <Text>{`Last Used: ${moment(item.lastRefresh).format(
                DATE_FORMAT
              )}`}</Text>
            </Card>
          </TouchableOpacity>
        )}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    pading: 25,
    width: Layout.window.width * 0.5,
    height: Layout.window.height * 0.7,
    alignSelf: "center",
    borderWidth: 5,
    borderColor: Colors.primary,
    borderRadius: 10,
  },
  label: {
    fontSize: 14,
    marginBottom: 5,
    color: Colors.primary, // Adjust label color as needed
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
  card: {
    borderRadius: 10,
    shadowColor: "#000",
    width: "100%",
    height: 150,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    padding: 20,
  },
});
