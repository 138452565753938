/* eslint-disable prettier/prettier */
import { StyleSheet } from "react-native";

import Colors from "./Colors";
import Layout from "./Layout";

const Styles = StyleSheet.create({
  fullHeight: {
    height: "100%",
  },
  transparantBackground: {
    backgroundColor: "transparent",
  },
  darkBackground: {
    backgroundColor: Colors.black,
  },
  selfCenter: {
    alignSelf: "center",
  },
  alignCenter: {
    alignItems: "center",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  centerTransparant: {
    backgroundColor: "transparent",
    alignItems: "center",
  },
  container: {
    flex: 1,
    paddingTop: 30,
  },
  containerPaddingBottom: {
    flex: 1,
    marginTop: 5,
    paddingTop: 30,
    paddingBottom: 50,
  },
  textTitle25: { fontSize: 25 },
  textColorMonkitox: { color: Colors.monkitox },
  textItalic: { fontStyle: "italic" },
  textTitle: {
    fontSize: 20,
    fontWeight: "bold",
  },
  textTitleCenter: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
  textSubTitle: {
    fontSize: 12,
    color: Colors.grey,
  },
  textTileTitle: {
    fontSize: 16,
  },
  textTitleWhite: {
    fontSize: 16,
    color: Colors.grey,
  },
  textWhiteCenter: {
    color: Colors.grey,
    textAlign: "center",
  },
  textError: { color: Colors.red, marginTop: 20, textAlign: "center" },
  textButton: {
    color: Colors.indigo,
    textDecorationLine: "underline",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  row: {
    flexDirection: "row",
  },
  rowCenter: {
    flexDirection: "row",
    alignItems: "center",
  },
  flex1: { flex: 1 },
  maxWidthSmall: { maxWidth: 300 },
  width70: { width: "70%" },
  width90: { width: "90%" },
  width100: { width: "100%" },
  tagContainer: {
    paddingHorizontal: 5,
    borderRadius: 5,
    backgroundColor: Colors.coral,
  },
  shrink: { flexShrink: 1 },
  flexGrow: { flexGrow: 1 },
  flexRow: { flexDirection: "row" },
  flexRowCenter: { flexDirection: "row", alignItems: "center" },
  flexRowEnd: { flexDirection: "row", justifyContent: "flex-end" },
  flexRowCenterSpaceBetween: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flexRowCenterSpaceAround: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  rowReverse: { flexDirection: "row-reverse" },
  marginHorizontal: { marginHorizontal: 10 },
  marginVertical: { marginVertical: 10 },
  marginLeft: { marginLeft: 10 },
  marginLeft25: { marginLeft: 25 },
  marginRight: { marginRight: 10 },
  marginTop: { marginTop: 10 },
  marginTop20: { marginTop: 20 },
  marginTop35: { marginTop: 35 },
  marginTop50: { marginTop: 50 },
  marginBottom50: { marginBottom: 50 },
  marginBottom: { marginBottom: 10 },
  paddingTop0: { paddingTop: 0 },
  paddingTop: { paddingTop: 10 },
  paddingTop50: { paddingTop: 50 },
  padding: { padding: 10 },
  margin: { margin: 10 },
  borderRadius: { borderRadius: 10 },
  paddingHorizontal: { paddingHorizontal: 10 },
  paddingVertical: { paddingVertical: 10 },
  flexCenter: { flex: 1, alignItems: "center", justifyContent: "center" },
  thumbnail: { ...Layout.thumbnailLarge, borderRadius: 5 },
  rotate: { transform: [{ rotate: "-45deg" }] },
  image: {
    height: 50,
    width: 50,
    borderRadius: 10,
  },
  imageLarge: {
    height: 100,
    width: 100,
    borderRadius: 10,
  },
  button: {
    marginTop: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: Colors.indigo,
    borderRadius: 5,
  },
  onboardContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  onboardLogo: {
    width: 100,
    height: 100,
    resizeMode: "contain",
  },
  onboardFormContainer: {
    width: "80%",
    borderRadius: 10,
  },
  onboardTitle: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
    alignSelf: "auto",
    color: "black",
  },
  onboardLabel: {
    fontSize: 14,
    marginBottom: 5,
    color: "black",
  },
  onboardInput: {
    height: 40,
    borderColor: "gray",
    borderWidth: 1,
    marginBottom: 10,
    padding: 10,
    borderRadius: 6,
    fontSize: 12,
  },
  onboardFileInput: {
    height: 40,
    borderColor: "gray",
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 1,
    marginBottom: 10,
    padding: 10,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    fontSize: 12,
    flexGrow: 1,
  },
  onboardUploadIcon: {
    height: 40,
    width: 40,
    borderColor: "gray",
    borderLeftWidth: 0,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    marginBottom: 10,
    padding: 10,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    fontSize: 12,
    alignItems: "center",
    justifyContent: "center",
  },
  onboardErrorText: {
    color: "red",
  },
  onboardRow: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  formButton: {
    paddingHorizontal: 5,
    marginHorizontal: 2,
    backgroundColor: Colors.primary,
    borderRadius: 5,
    width: 200,
    height: 40,
    alignSelf: "flex-end",
    marginVertical: 20,
    justifyContent: "center",
  },
  formButtonLabel: {
    fontSize: 20,
    textAlign: "center",
  },
  buttonContainer: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
  },
  rowStyle: {
    flexDirection: "row",
    marginTop: 16,
  },
  columnStyle: {
    flex: 1,
    flexDirection: "column",
    marginRight: 10,
  },
  fileInput: {
    height: 40,
    // width: "100%",
    borderColor: "gray",
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 1,
    marginBottom: 10,
    padding: 10,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    fontSize: 12,
    flexGrow: 1,
  },
  uploadIcon: {
    height: 40,
    width: 40,
    borderColor: "gray",
    borderLeftWidth: 0,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    marginBottom: 10,
    padding: 10,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    fontSize: 12,
    alignItems: "center",
    justifyContent: "center",
  },
  dropdown: {
    height: 40,
    width: 250,
    // width: "100%",
    borderColor: "gray",
    borderWidth: 1,
    marginBottom: 10,
    padding: 10,
    borderRadius: 6,
    fontSize: 12,
    backgroundColor: Colors.white,
    zIndex: 1000,
  },
  errorText: {
    color: "red",
  },
  productFormContainer: {
    width: "100%",
    padding: 20,
    borderRadius: 10,
  },
});

export default Styles;
