import {
  GestureResponderEvent,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import React from "react";
import { MaterialCommunityIcons } from "@expo/vector-icons";

const CheckBox = (props: {
  isChecked?: boolean;
  onPress?: ((event: GestureResponderEvent) => void) | null;
  title?: string;
}) => {
  const iconName = props.isChecked
    ? "checkbox-marked"
    : "checkbox-blank-outline";

  return (
    <>
      <View style={styles.container}>
        <Pressable onPress={props.onPress}>
          <MaterialCommunityIcons
            name={iconName}
            size={24}
            color="white"
            style={{ backgroundColor: "#000" }}
          />
        </Pressable>
      </View>
      <Text style={styles.title}>{props.title}</Text>
    </>
  );
};

export default CheckBox;

const styles = StyleSheet.create({
  container: {
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    marginTop: 18,
    marginEnd: 5,
  },
  title: {
    fontSize: 16,
    color: "#000",
    marginLeft: 5,
    fontWeight: "600",
  },
});
