import React from "react";
import { View, Text, TextInput, Switch, TouchableOpacity } from "react-native";
import { useForm, Controller } from "react-hook-form";
import Colors from "../constants/Colors";
import Styles from "../constants/Styles";

interface formInputProps {
  label?: string;
  value?: string;
  onChangeText?: any;
  multiline?: boolean;
  control?: any;
  name?: string;
  rules?: any;
  defaultValue?: string;
  errors?: any;
  isSwitch?: boolean;
  switchLabels?: any;
  customOnChange?: any;
}
const FormInput: React.FC<formInputProps> = ({
  label,
  control,
  name,
  rules,
  defaultValue,
  errors,
  isSwitch = false,
  switchLabels,
  customOnChange,
}) => {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        marginRight: 10,
      }}
    >
      <Text style={Styles.onboardLabel}>{label}</Text>
      <Controller
        control={control}
        render={({ field }) =>
          isSwitch ? (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Switch
                value={field.value === "Yes"}
                onValueChange={(value) => field.onChange(value ? "Yes" : "No")}
              />
              <Text style={{ color: "black", marginLeft: 10 }}>
                {field.value === "Yes"
                  ? switchLabels?.true
                  : switchLabels?.false}
              </Text>
              {/* Use switchLabels prop here */}
            </View>
          ) : (
            <TextInput
              style={Styles.onboardInput}
              placeholder={label}
              placeholderTextColor="#777"
              onChangeText={(text) => {
                if (customOnChange) {
                  customOnChange(text); // Call customOnChange if provided
                } else {
                  field.onChange(text); // Otherwise, call default onChange
                }
              }}
              value={field.value}
            />
          )
        }
        name={name as string}
        rules={rules}
        defaultValue={defaultValue}
      />
      {errors[name as string] && (
        <Text style={Styles.onboardErrorText}>
          {errors[name as string].message}
        </Text>
      )}
    </View>
  );
};

export default FormInput;
