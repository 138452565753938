/* eslint-disable prettier/prettier */

const tintColorDark = "#fff";
const tintColorLight = "#000";

export default {
  pastelColors: [
    "#FFADAD",
    "#9BF6FF",
    "#A0C4FF",
    "#BDB2FF",
    "#CAFFBF",
    "#FFC6FF",
  ],
  white: "white",
  grey: "grey",
  black: "black",
  noBackground: "#00000000",
  indigo: "#3C6478",
  alice: "#1496BB",
  coral: "#F26D21",
  monkitox: "#ff9f40",
  ruby: "#C02F1D",
  daisy: "#EBC944",
  kelly: "#A3B86C",
  lime: "#00FF00",
  primary: "#ef7d10",
  pink: "#ffb6c1",
  blue: "#5CE1E6",
  yellow: "yellow",
  orange: "orange",
  red: "red",
  gold: "#FFD700",
  whatsApp: "#25D366",
  light: {
    text: "#000",
    background: "#fff",
    tint: tintColorLight,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorLight,
    primary: "#ef7d10",
    secondary: "#ef7d10",
  },
  dark: {
    text: "#fff",
    background: "#000",
    tint: tintColorDark,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorDark,
    primary: "#ef7d10",
    secondary: "#ef7d10",
  },
};
