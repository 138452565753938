/**
 * Learn more about Light and Dark modes:
 * https://docs.expo.io/guides/color-schemes/
 */

import React from "react";

import { FontAwesome } from "@expo/vector-icons";
import {
  ActivityIndicator as DefaultActivityIndicator,
  Modal as DefaultModal,
  Text as DefaultText,
  View as DefaultView,
  TextInputProps,
} from "react-native";
import { TextInput as ActualTextInput } from "react-native-paper";

import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";
import { Input } from "@chakra-ui/react";

export function useThemeColor(
  props: { light?: string; dark?: string },
  colorName: keyof typeof Colors.light & keyof typeof Colors.dark
) {
  const theme = useColorScheme();
  const colorFromProps = props[theme];

  if (colorFromProps) {
    return colorFromProps;
  }
  return Colors[theme][colorName];
}

type ThemeProps = {
  lightColor?: string;
  darkColor?: string;
};

type ActivityIndicatorProps = {
  size?: string;
  color?: string;
  isLoading: boolean;
} & ThemeProps;

export type TextProps = ThemeProps & DefaultText["props"];
export type ViewProps = ThemeProps & DefaultView["props"];

export function Text(props: TextProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, "text");

  return <DefaultText style={[{ color }, style]} {...otherProps} />;
}

export function View(props: ViewProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const backgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    "background"
  );

  return <DefaultView style={[{ backgroundColor }, style]} {...otherProps} />;
}

export function ActivityIndicator(props: ActivityIndicatorProps) {
  const { lightColor, darkColor, ...otherProps } = props;
  const color = useThemeColor(
    { light: lightColor, dark: darkColor },
    "primary"
  );
  return props.isLoading ? (
    <DefaultActivityIndicator size={"large"} color={color} {...otherProps} />
  ) : null;
}

export function Modal(props: ViewProps) {
  const { ...otherProps } = props;

  return (
    <DefaultModal animationType="slide" transparent={true} {...otherProps} />
  );
}

export function ChakraInput(
  props: TextInputProps & {
    onChangeText?: (text: string) => void;
    label?: string;
    backgroundColor?: string;
    width?: string | number;
    labelRightElement?: React.ReactNode;
  }
) {
  return (
    <View
      style={[
        {
          backgroundColor: props.backgroundColor || Colors.white,
          width: props.width || 300,
        },
        props.style,
      ]}
    >
      {props.label && (
        <Text style={{ color: Colors.black, marginBottom: 5 }}>
          {props.label}
          {props.labelRightElement}
        </Text>
      )}

      <Input
        placeholder={props.placeholder}
        value={props.value}
        onChange={(event) => {
          props?.onChangeText?.(event.target.value);
        }}
      />
    </View>
  );
}

export function CTextInput(props: TextInputProps & { icon?: string }) {
  return (
    <ActualTextInput
      mode="outlined"
      {...props}
      style={[props.style, { color: Colors.primary }]}
      outlineColor={Colors.grey}
      activeOutlineColor={Colors.primary}
      placeholderTextColor={Colors.grey}
      theme={{
        colors: {
          text: Colors.white,
          primary: Colors.white,
          accent: Colors.grey,
          placeholder: Colors.grey,
        },
      }}
      left={
        props.icon ? (
          <ActualTextInput.Icon
            name={() => (
              <FontAwesome name={props.icon} color={"grey"} size={20} />
            )}
          />
        ) : null
      }
    />
  );
}
