/* eslint-disable react-native/no-inline-styles */
import React, { Component } from "react";

import { FontAwesome } from "@expo/vector-icons";
import * as Linking from "expo-linking";
import { StatusBar } from "expo-status-bar";
import {
  Animated,
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import FadeInFlatlist from "../components/FadeInFlatlist";
import FontAwesomeButton from "../components/FontAwesomButton";
import Colors from "../constants/Colors";
import Contents from "../constants/Contents";
import Layout from "../constants/Layout";

export default class WebHomeScreen extends Component {
  state = {
    fadeAnim: new Animated.Value(0),
    animated: new Animated.Value(0),
  };

  componentDidMount() {
    setTimeout(() => {
      //Wait for images to load before printing
      this.fadeIn();
    }, 1000);

    const duration = 5000;
    Animated.loop(
      Animated.sequence([
        Animated.timing(this.state.animated, {
          toValue: 50,
          duration: duration,
          useNativeDriver: true,
        }),
        Animated.timing(this.state.animated, {
          toValue: 0,
          duration: duration,
          useNativeDriver: true,
        }),
      ])
    ).start();
  }

  fadeIn = () => {
    // Will change fadeAnim value to 1 in 5 seconds
    Animated.timing(this.state.fadeAnim, {
      toValue: 1,
      duration: 3000,
      useNativeDriver: false,
    }).start();
  };

  fadeOut = () => {
    // Will change fadeAnim value to 0 in 3 seconds
    Animated.timing(this.state.fadeAnim, {
      toValue: 0,
      duration: 3000,
      useNativeDriver: false,
    }).start();
  };

  render() {
    return (
      <ScrollView contentContainerStyle={styles.container}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: Layout.window.width - 30,
          }}
        >
          <Image
            source={require("../assets/images/icon.png")}
            style={{ width: 50, height: 50, marginRight: 10, borderRadius: 5 }}
          />

          <View
            style={{
              flexDirection: "row",
              backgroundColor: "transparent",
              width: 150,
              justifyContent: "space-between",
            }}
          >
            <FontAwesomeButton
              name={"instagram"}
              color={Colors.white}
              size={30}
              onButtonPressed={() =>
                Linking.openURL("https://www.instagram.com/monki.tox/?hl=en")
              }
            />
            <FontAwesomeButton
              name={"facebook"}
              color={Colors.white}
              size={30}
              onButtonPressed={() =>
                Linking.openURL(
                  "https://www.facebook.com/Monki-Tox-102247262512560"
                )
              }
            />
            <FontAwesomeButton
              name={"twitter"}
              color={Colors.white}
              size={30}
              onButtonPressed={() => null}
            />
          </View>
        </View>

        <Text style={{ color: Colors.monkitox, fontSize: 70, marginTop: 20 }}>
          {Contents.title}
        </Text>
        <Animated.View
          style={[
            styles.fadingContainer,
            {
              // Bind opacity to animated value
              opacity: this.state.fadeAnim,
            },
          ]}
        >
          <Text style={{ color: "grey", textAlign: "center", fontSize: 50 }}>
            {Contents.subTitle}
          </Text>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-around",
              width: 350,
              marginTop: 20,
            }}
          >
            <TouchableOpacity
              onPress={() =>
                Linking.openURL(
                  "https://play.google.com/store/apps/details?id=com.monkitox.app"
                )
              }
            >
              <Image
                source={require("../assets/images/googleplay.png")}
                style={{
                  width: 150,
                  height: 45,
                  borderColor: "white",
                  borderWidth: 1,
                  borderRadius: 5,
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() =>
                Linking.openURL(
                  "https://apps.apple.com/us/app/monkitox/id1644925345"
                )
              }
            >
              <Image
                source={require("../assets/images/appstore.png")}
                style={{
                  width: 150,
                  height: 45,
                  borderColor: "white",
                  borderWidth: 1,
                  borderRadius: 5,
                }}
              />
            </TouchableOpacity>
          </View>
        </Animated.View>
        <View
          style={{
            flexDirection:
              Layout.window.width > Layout.window.height ? "row" : "column",
            paddingBottom: 50,
          }}
        >
          <Animated.View
            style={[
              styles.fadingContainer,
              {
                // Bind opacity to animated value
                opacity: this.state.fadeAnim,
              },
            ]}
          >
            <View
              style={{
                marginTop: 10,
                padding: 20,
                maxWidth: 300,
                borderColor: Colors.grey,
                borderWidth: 2,
                borderRadius: 10,
                margin: 10,
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: 18,
                  color: Colors.monkitox,
                  marginBottom: 20,
                }}
              >
                Looking for ways to keep your children productively occupied?
              </Text>
              <Text style={{ fontSize: 13, color: Colors.grey, marginTop: 10 }}>
                100% child friendly content, several segments, multiple
                languages, unlimited joy - It's all about Monki Tox. The first
                of its kind radio for kids to promote imagination, listening
                skills, learning, vocabulary and creativity.
              </Text>
              {/* <View>
                                <Text style={{ fontSize: 15, color: Colors.monkitox }}>1. Moral Stories for kids</Text>
                                <Text style={{ fontSize: 13, color: Colors.grey, marginTop: 10 }}>Child friendly content curated by parents, caregivers, teachers & childcare experts</Text>
                            </View>
                            <View>
                                <Text style={{ fontSize: 15, color: Colors.monkitox, marginTop: 20 }}>2. General knowledge & Fun facts</Text>
                                <Text style={{ fontSize: 13, color: Colors.grey, marginTop: 10 }}>Real-life inspirations, knowledge & skill development</Text>
                            </View>
                            <View>
                                <Text style={{ fontSize: 15, color: Colors.monkitox, marginTop: 20 }}>3. Multi-lingual</Text>
                                <Text style={{ fontSize: 13, color: Colors.grey, marginTop: 10 }}> Available in English and popular Indian languages</Text>
                            </View> */}
            </View>
          </Animated.View>
          <FadeInFlatlist
            style={{ marginTop: 20 }}
            data={features}
            numColumns={2}
            renderItem={({ item, index }) => (
              <View
                style={{
                  maxWidth: 200,
                  width: Layout.window.width / 2 - 10,
                  padding: 10,
                  borderRadius: 5,
                  borderWidth: 0,
                  borderColor: Colors.monkitox,
                  marginLeft: index % 2 == 1 ? 5 : 12,
                  marginTop: 5,
                }}
              >
                <FontAwesome
                  name={item.icon}
                  color={Colors.monkitox}
                  size={35}
                />
                <Text style={{ color: "white", fontSize: 15 }}>
                  {item.title}
                </Text>
                <Text style={{ color: "grey", fontSize: 13 }}>
                  {item.content}
                </Text>
              </View>
            )}
          />
        </View>
        {Layout.window.height > Layout.window.width ? (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-around",
              width: 350,
              marginTop: 20,
            }}
          >
            <TouchableOpacity
              onPress={() =>
                Linking.openURL(
                  "https://play.google.com/store/apps/details?id=com.storemystore.seller&hl=en_IN"
                )
              }
            >
              <Image
                source={require("../assets/images/googleplay.png")}
                style={{
                  width: 150,
                  height: 45,
                  borderColor: "white",
                  borderWidth: 1,
                  borderRadius: 5,
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() =>
                Linking.openURL(
                  "https://apps.apple.com/in/app/storemystore-business/id1503665987"
                )
              }
            >
              <Image
                source={require("../assets/images/appstore.png")}
                style={{
                  width: 150,
                  height: 45,
                  borderColor: "white",
                  borderWidth: 1,
                  borderRadius: 5,
                }}
              />
            </TouchableOpacity>
          </View>
        ) : null}
        <TouchableOpacity
          onPress={() => this.props.navigation.navigate("TnC")}
          onLongPress={() => this.props.navigation.navigate("Admin")}
        >
          <Text style={{ textAlign: "center", margin: 10, color: Colors.grey }}>
            Terms & Conditions | Privacy Policy
          </Text>
        </TouchableOpacity>
        <StatusBar style="auto" />
      </ScrollView>
    );
  }
}

const features = [
  {
    id: 1,
    icon: "podcast",
    title: "Moral Stories for kids",
    content:
      "Child friendly content curated by parents, caregivers, teachers & childcare experts",
  },
  {
    id: 2,
    icon: "lightbulb-o",
    title: "General knowledge & Fun facts",
    content: "Real-life inspirations, knowledge & skill development",
  },
  {
    id: 3,
    icon: "language",
    title: "Multi-lingual",
    content: "Available in English and popular Indian languages",
  },
];

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "black",
    alignItems: "center",
    paddingTop: 20,
  },
  fadingContainer: {
    padding: 20,
    backgroundColor: "black",
    alignItems: "center",
  },
});
