import React from 'react';

import { FontAwesome } from '@expo/vector-icons';

import { Text, TextProps, View } from './Themed';
import Colors from '../constants/Colors';

export function MonoText(props: TextProps) {
 return <Text {...props} style={[props.style, { fontFamily: 'space-mono' }]} />;
}

export function GradientText(props: TextProps) {
 return <Text {...props} style={[props.style, { fontFamily: 'space-mono' }]} />;
}

export function Header(props: TextProps) {
 return (
  <Text
   {...props}
   style={([props.style], { color: Colors.coral, fontSize: 20, fontWeight: 'bold', marginTop: 20 })}
  />
 );
}

export function Bullet1(props: TextProps) {
 return (
  <View style={{ flexDirection: 'row', marginLeft: 20, marginTop: 10 }}>
   <FontAwesome name="dot-circle-o" color={Colors.grey} size={14} />
   <Text {...props} style={([props.style], { color: 'white', fontSize: 14, marginLeft: 5 })} />
  </View>
 );
}

export function Bullet2(props: TextProps) {
 return (
  <View style={{ flexDirection: 'row', marginLeft: 40, marginTop: 10 }}>
   <FontAwesome name="circle" color={Colors.grey} size={14} />
   <Text {...props} style={([props.style], { color: 'white', fontSize: 14, marginLeft: 5 })} />
  </View>
 );
}

export default GradientText;
