import React from "react";

import { ChakraProvider } from "@chakra-ui/react";
import { StatusBar } from "expo-status-bar";
import { initializeApp } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";
import { Provider as PaperProvider } from "react-native-paper";
import { SafeAreaProvider } from "react-native-safe-area-context";

import useCachedResources from "./hooks/useCachedResources";
import useColorScheme from "./hooks/useColorScheme";
import Navigation from "./navigation";

const firebaseConfig = {
  apiKey: "AIzaSyDzPwl8R8xY9XcTpjFt0Ri4LVGJI4tq01I",
  authDomain: "monkitox-podcasts.firebaseapp.com",
  projectId: "monkitox-podcasts",
  storageBucket: "monkitox-podcasts.appspot.com",
  messagingSenderId: "842438460443",
  appId: "1:842438460443:web:dfc6903f1b2a12ace3a4be",
  measurementId: "G-N96XH7FY05",
};

const app = initializeApp(firebaseConfig);
const db = initializeFirestore(app, {
  experimentalForceLongPolling: true,
});

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  if (!isLoadingComplete) {
    return null;
  }

  return (
    <ChakraProvider>
      <PaperProvider>
        <SafeAreaProvider>
          <Navigation colorScheme={colorScheme} />
          <StatusBar />
        </SafeAreaProvider>
      </PaperProvider>
    </ChakraProvider>
  );
}
