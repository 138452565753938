/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
/* eslint-disable react-native/no-inline-styles */
window.Buffer = window.Buffer || require("buffer").Buffer;

import { Switch } from "@chakra-ui/react";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  FlatList,
  Image,
  Linking,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { Dropdown } from "react-native-element-dropdown";
import { Button, Modal, Portal, Provider } from "react-native-paper";
import S3FileUpload from "react-s3";
import FontAwesomeButton from "../components/FontAwesomButton";
import MultiSelectInput from "../components/MultiSelectInput";
import TextInput from "../components/TextInput";
import { ActivityIndicator, Text, View } from "../components/Themed";
import Colors from "../constants/Colors";
import Contents from "../constants/Contents";
import Layout from "../constants/Layout";
import Styles from "../constants/Styles";
import useColorScheme from "../hooks/useColorScheme";
import { Ads, RootTabScreenProps, Vendor } from "../types";
import { _searchList, getDefaultThumbnail } from "../utils/helper";
import { omit } from "lodash";

const S3_BUCKET = "monkitoxs3";
const REGION = "us-east-2";
const ACCESS_KEY = "AKIAZ2BTAGOCP5TBLCTM";
const SECRET_ACCESS_KEY = "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";
const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

//const analytics = getAnalytics(app);

export default function AdsList({
  navigation,
}: RootTabScreenProps<"Discover">) {
  const theme = useColorScheme();
  const [isAudioUploading, setIsAudioUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userPhone, setUserPhone] = useState<string | undefined | null>();
  const [isUpdating, setIsUpdating] = useState(false);

  const [addAdsModalVisible, setAddAdsModalVisible] = useState(false);
  useState(false);
  const [statisticsModal, setStatisticsModal] = useState(false);
  const [vendorList, setVendorList] = useState<Vendor[]>([]);
  const [filteredAdsList, setFilteredAdsList] = useState<Ads[]>();
  const [adList, setAdList] = useState<Ads[]>([]);
  const [ad, setAd] = useState<Ads>();
  const [searchText, setSearchText] = useState<string>();
  const horizontalBannerInputRef = useRef(null);
  const verticalBannerInputRef = useRef(null);

  const db = getFirestore();

  useEffect(() => {
    if (getAuth().currentUser) {
      setUserPhone(getAuth().currentUser?.phoneNumber);
    }
  }, []);

  useEffect(() => {
    if (searchText && searchText !== "") {
      setFilteredAdsList(_searchList(adList, searchText));
    } else {
      setFilteredAdsList(undefined);
    }
  }, [adList, searchText]);

  const handleUpload = useCallback(async (file: any, field: string) => {
    setIsAudioUploading(true);
    S3FileUpload.uploadFile(file, config)
      .then((data: any) => {
        console.log(data);
        const dataArray = ad?.[field] || [];
        dataArray.push({
          url: data.location,
          listens: 0,
          interaction: 0,
          status: true,
        });
        setAd((currentValue) => {
          return {
            ...currentValue,
            [field]: dataArray,
          };
        });
        setIsAudioUploading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsAudioUploading(false);
        alert("Something went wrong while uploading audio file");
      });
  }, []);

  const handleFileSelect = useCallback(
    (e: any, field: string) => {
      setIsAudioUploading(true);
      handleUpload(e.target.files[0], field);
    },
    [handleUpload]
  );

  const saveChanges = useCallback(async () => {
    if (!ad) {
      return;
    }
    if (
      !ad.vendor ||
      !ad.title ||
      !ad.package ||
      !ad.ctaText ||
      !ad.ctaAction
    ) {
      alert("Enter all mandatory fields");
      return;
    }
    if (ad.key) {
      await updateDoc(
        doc(db, "ads", ad.key),
        omit(ad, "viewed", "heard", "clicked", "credits")
      );

      const updatedList = adList;
      const index = adList?.findIndex((x) => x.key === ad.key);
      if (updatedList && index) {
        updatedList[index] = ad;
        setAdList(updatedList);

        const updatedFilteredList = filteredAdsList;
        const filteredIndex = filteredAdsList?.findIndex(
          (x) => x.key === ad.key
        );
        if (
          filteredIndex !== undefined &&
          filteredIndex >= 0 &&
          updatedFilteredList
        ) {
          updatedFilteredList[filteredIndex] = ad;
          setFilteredAdsList(updatedFilteredList);
        }
      }

      alert("Updated Successfully");
    } else {
      const adRef = await addDoc(collection(db, "ads"), {
        ...ad,
        createdAt: serverTimestamp(),
      });
      const updatedList = adList;
      updatedList.push({ ...ad, key: adRef.id });
      setAdList(updatedList);
      setAd({ ...ad, key: adRef.id });

      const updatedFilteredList = filteredAdsList;
      updatedFilteredList?.push({
        ...ad,
        key: adRef.id,
      });
      setFilteredAdsList(updatedFilteredList);
      alert("Created Successfully");
    }
  }, [ad, adList, db, filteredAdsList]);

  const listItem = useCallback(
    ({
      item,
      onPress,
    }: {
      item: Ads;
      index: number;
      onPress?: (item: Ads) => void;
    }) => {
      return (
        <TouchableOpacity
          style={{
            marginLeft: 5,
            flexDirection: "row",
            marginTop: 5,
            width: 500,
          }}
          onPress={() => {
            if (onPress) {
              return onPress?.(item);
            }
            setAd(item);
            setAddAdsModalVisible(true);
          }}
        >
          <View style={{ padding: 5, width: 500 }}>
            <View
              style={[Styles.flexRowCenterSpaceBetween, { paddingRight: 10 }]}
            >
              <Text style={[Styles.textTitle]}>{item.title}</Text>
              <Text
                style={[
                  Styles.textTitle,
                  { color: item.status ? Colors.kelly : Colors.red },
                ]}
              >
                {item.status ? "Active" : "Inactive"}
              </Text>
            </View>
            <View
              style={[Styles.flexRowCenterSpaceBetween, { paddingRight: 10 }]}
            >
              <Text style={[Styles.textSubTitle]}>
                {`Audio:${item.audio.length} HB:${item.horizontalBanners.length} VB:${item.verticalBanners.length}`}
              </Text>
              <Text style={[Styles.textSubTitle]}>
                {moment(item.createdAt?.toDate()).format("DD-MMM-YYYY HH:mm")}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      );
    },
    []
  );

  const renderList = useCallback(() => {
    return (
      <FlatList
        data={filteredAdsList || adList}
        style={{
          marginTop: 10,
          paddingLeft: 50,
          height: Layout.window.height * 0.75,
          //marginBottom: 100 + ((playingNow.isPlayingNow || playingNow.Title || playingNow.live) ? 50 : 0)
        }}
        ListHeaderComponent={
          <View>
            <View style={Styles.flexRowCenterSpaceAround}>
              <TouchableOpacity onPress={() => setStatisticsModal(true)}>
                <Text style={{ fontSize: 20, color: Colors.primary }}>
                  {adList.length} Ads
                </Text>
              </TouchableOpacity>

              <Button
                mode="contained"
                buttonColor={Colors.primary}
                onPress={() => {
                  setAd({
                    status: true,
                    package: "Silver",
                    verticalBanners: [],
                    horizontalBanners: [],
                    audio: [],
                    title: "",
                    vendor: undefined,
                    ctaText: "",
                    ctaAction: "None",
                    ctaTarget: "",
                    credits: 0,
                    language: "All",
                  });
                  setAddAdsModalVisible(true);
                }}
              >
                Add Ads
              </Button>
            </View>
            <TextInput
              value={searchText}
              label={"Search"}
              onChangeText={setSearchText}
            />
          </View>
        }
        keyExtractor={(item) => item.key || ""}
        renderItem={listItem}
      />
    );
  }, [adList, filteredAdsList, listItem, searchText]);

  useEffect(() => {
    async function fetchMyAPI() {
      setIsLoading(true);
      const q = query(collection(db, "ads"), orderBy("title"));
      const ads: Ads[] = [];

      const querySnapshot = await getDocs(q);
      for (const [index, doc] of querySnapshot.docs.entries()) {
        const _ad = doc.data() as Ads;
        _ad.key = doc.id;

        ads.push(_ad);
      }
      const vendorData = query(collection(db, "Vendors"), orderBy("Name"));
      const vendors: any = [];
      const vendorQuery = await getDocs(vendorData);

      for (const [index, doc] of vendorQuery.docs.entries()) {
        const _vendor = doc.data() as any;
        _vendor.key = doc.id;
        if (!_vendor.Thumbnail) {
          _vendor.Thumbnail = getDefaultThumbnail(index);
        }
        vendors.push(_vendor);
      }
      setVendorList(vendors);
      setAdList(ads);
      setIsLoading(false);
    }

    fetchMyAPI();
  }, [db]);

  return (
    <Provider>
      <Portal>
        <Modal
          visible={addAdsModalVisible}
          onDismiss={() => setAddAdsModalVisible(false)}
          contentContainerStyle={styles.modalContainer}
        >
          <ScrollView style={{ flex: 1, padding: 20 }}>
            <View style={Styles.flexRowCenterSpaceBetween}>
              <View style={Styles.flexRow}>
                <Text style={{ fontSize: 16, marginBottom: 20 }}>
                  {"Add/ Update Ads   "}
                </Text>
                <Switch
                  isChecked={ad?.status}
                  onChange={(event) => {
                    setAd((currentValue) => {
                      return {
                        ...currentValue,
                        status: event.target.checked,
                      };
                    });
                  }}
                />
              </View>
              <FontAwesomeButton
                onButtonPressed={() => {
                  setAddAdsModalVisible(false);
                }}
                name="times"
                size={30}
                color={Colors.primary}
              />
            </View>
            <View style={Styles.flexRowCenterSpaceBetween}>
              <View>
                <Text style={{ color: Colors.primary, fontSize: 16 }}>
                  Vendor
                </Text>
                <Dropdown
                  style={Styles.dropdown}
                  placeholderStyle={styles.placeholderStyle}
                  selectedTextStyle={styles.selectedTextStyle}
                  data={vendorList}
                  maxHeight={300}
                  labelField="Name"
                  valueField="key"
                  placeholder="Select item"
                  value={vendorList.find((vendor) => vendor.key === ad?.vendor)}
                  onChange={(vendor) => {
                    setAd({ ...ad, vendor: vendor.key || "" });
                  }}
                />
              </View>
              <View style={{ width: 350 }}>
                <MultiSelectInput
                  label="Package"
                  options={["Silver", "Gold", "Platinum"]}
                  values={[ad?.package]}
                  singleSelect
                  onUpdate={(values: string[]) => {
                    setAd((currentValue) => {
                      return { ...currentValue, package: values[0] };
                    });
                  }}
                />
              </View>
            </View>
            <TextInput
              value={ad?.title}
              label={"Ad Title"}
              onChangeText={(value: string) => {
                setAd((currentValue) => {
                  return { ...currentValue, title: value };
                });
              }}
            />
            <View style={Styles.flexRowCenterSpaceBetween}>
              <TextInput
                value={ad?.ctaText}
                label={"CTA Text"}
                onChangeText={(value: string) => {
                  setAd((currentValue) => {
                    return { ...currentValue, ctaText: value };
                  });
                }}
              />
              <View style={{ width: 400 }}>
                <MultiSelectInput
                  label="CTA Action"
                  width={400}
                  options={[
                    "Navigate",
                    "Link",
                    "None",
                    "Premium",
                    "Call",
                    "Shop",
                  ]}
                  values={[ad?.ctaAction]}
                  singleSelect
                  onUpdate={(values: string[]) => {
                    setAd((currentValue) => {
                      return { ...currentValue, ctaAction: values[0] };
                    });
                  }}
                />
              </View>
            </View>
            <TextInput
              value={ad?.ctaTarget}
              label={"CTA Target"}
              onChangeText={(value: string) => {
                setAd((currentValue) => {
                  return { ...currentValue, ctaTarget: value };
                });
              }}
            />
            <MultiSelectInput
              label="Target Audience"
              options={["All", ...Contents.languages]}
              values={[ad?.language || "All"]}
              singleSelect
              onUpdate={(values: string[]) => {
                setAd((currentValue) => {
                  return { ...currentValue, language: values[0] };
                });
              }}
            />

            <View>
              <Text style={{ fontSize: 20, color: Colors.primary }}>
                Vertical Banners
                <Text style={{ fontSize: 10 }}> Width:270px & Height:540</Text>
              </Text>
              <FlatList
                data={ad?.verticalBanners}
                style={{
                  marginTop: 10,
                  paddingLeft: 50,
                }}
                horizontal
                keyExtractor={(item) => item.url || ""}
                renderItem={({ item, index }) => (
                  <View
                    style={{
                      flexDirection: "row",
                      marginRight: 10,
                    }}
                  >
                    <Image
                      source={{ uri: item.url }}
                      style={{
                        width: 100,
                        height: 125,
                        borderRadius: 10,
                      }}
                    />
                    <View style={{ padding: 5 }}>
                      <Text style={[Styles.textTileTitle]}>
                        Views: {item.views}
                      </Text>

                      <Text style={[Styles.textSubTitle]}>
                        Interaction: {item.interaction}
                      </Text>
                      <Switch
                        style={{ marginTop: 10 }}
                        isChecked={item.status}
                        onChange={(event) => {
                          const updatedBanners = ad?.verticalBanners || [];
                          updatedBanners[index].status = event.target.checked;
                          setAd((currentValue) => {
                            return {
                              ...currentValue,
                              verticalBanners: updatedBanners,
                            };
                          });
                        }}
                      />
                    </View>
                  </View>
                )}
                ListFooterComponent={
                  <>
                    <input
                      type="file"
                      id="file"
                      ref={verticalBannerInputRef}
                      style={{ display: "none" }}
                      onChange={(e) => handleFileSelect(e, "verticalBanners")}
                    />
                    <FontAwesomeButton
                      onButtonPressed={() => {
                        // pickImage("verticalBanners");
                        verticalBannerInputRef?.current?.click();
                      }}
                      name="plus-square"
                      size={60}
                      color={Colors.primary}
                    />
                  </>
                }
              />
            </View>
            <View>
              <Text style={{ fontSize: 20, color: Colors.primary }}>
                Horizontal Banners
                <Text style={{ fontSize: 10 }}> Width:540px & Height:270</Text>
              </Text>
              <FlatList
                data={ad?.horizontalBanners}
                style={{
                  marginTop: 10,
                  paddingLeft: 50,
                }}
                horizontal
                keyExtractor={(item) => item.url || ""}
                renderItem={({ item, index }) => (
                  <View
                    style={{
                      flexDirection: "row",
                      marginRight: 10,
                    }}
                  >
                    <Image
                      source={{ uri: item.url }}
                      style={{
                        width: 100,
                        height: 50,
                        borderRadius: 10,
                      }}
                    />
                    <View style={{ padding: 5 }}>
                      <Text style={[Styles.textTileTitle]}>
                        Views: {item.views}
                      </Text>

                      <Text style={[Styles.textSubTitle]}>
                        Interaction: {item.interaction}
                      </Text>
                      <Switch
                        style={{ marginTop: 10 }}
                        isChecked={item.status}
                        onChange={(event) => {
                          const updatedBanners = ad?.horizontalBanners || [];
                          updatedBanners[index].status = event.target.checked;
                          setAd((currentValue) => {
                            return {
                              ...currentValue,
                              horizontalBanners: updatedBanners,
                            };
                          });
                        }}
                      />
                    </View>
                  </View>
                )}
                ListFooterComponent={
                  <>
                    <input
                      type="file"
                      id="file"
                      ref={horizontalBannerInputRef}
                      style={{ display: "none" }}
                      onChange={(e) => handleFileSelect(e, "horizontalBanners")}
                    />
                    <FontAwesomeButton
                      onButtonPressed={() => {
                        horizontalBannerInputRef?.current?.click();
                      }}
                      name="plus-square"
                      size={60}
                      color={Colors.primary}
                    />
                  </>
                }
              />
            </View>
            <View>
              <Text style={{ fontSize: 20, color: Colors.primary }}>
                {"Audio  "}
                <Text style={{ fontSize: 10 }}>Preferrably 30s</Text>
              </Text>
              <FlatList
                data={ad?.audio}
                style={{
                  marginTop: 10,
                  paddingLeft: 50,
                }}
                horizontal
                keyExtractor={(item) => item.url || ""}
                renderItem={({ item, index }) => (
                  <View
                    style={{
                      flex: 2,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {isAudioUploading ? (
                      <ActivityIndicator
                        isLoading
                        size="large"
                        color={Colors[theme].primary}
                      />
                    ) : (
                      <View
                        style={{
                          marginRight: 10,
                          borderRadius: 5,
                          borderWidth: 2,
                          borderColor: Colors.primary,
                          padding: 5,
                        }}
                      >
                        <Text>{item.url.replace(/^.*[\\/]/, "")}</Text>
                        <View
                          style={{
                            padding: 5,
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <View>
                            <Text style={[Styles.textTileTitle]}>
                              Views: {item.listens}
                            </Text>

                            <Text style={[Styles.textSubTitle]}>
                              Interaction: {item.interaction}
                            </Text>
                          </View>
                          <Switch
                            style={{ marginTop: 10 }}
                            isChecked={item.status}
                            onChange={(event) => {
                              const updatedBanners = ad?.verticalBanners || [];
                              updatedBanners[index].status =
                                event.target.checked;
                              setAd((currentValue) => {
                                return {
                                  ...currentValue,
                                  verticalBanners: updatedBanners,
                                };
                              });
                            }}
                          />
                          <FontAwesomeButton
                            name="play"
                            size={30}
                            color={Colors.primary}
                            onButtonPressed={() => {
                              Linking.openURL(item.url);
                            }}
                          />
                        </View>
                      </View>
                    )}
                  </View>
                )}
                ListFooterComponent={
                  <input
                    type="file"
                    onChange={(e) => handleFileSelect(e, "audio")}
                  />
                }
              />
            </View>
            {isUpdating ? (
              <ActivityIndicator
                isLoading
                size="large"
                color={Colors[theme].primary}
              />
            ) : (
              <TouchableOpacity
                style={{
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  backgroundColor: Colors.primary,
                  borderRadius: 5,
                  alignSelf: "center",
                  marginVertical: 20,
                }}
                onPress={() => {
                  saveChanges();
                }}
              >
                <Text style={{ fontSize: 20 }}>{`${
                  ad?.key ? "Update" : "Add"
                } Advertisement`}</Text>
              </TouchableOpacity>
            )}
          </ScrollView>
        </Modal>
      </Portal>
      <ScrollView
        contentContainerStyle={[
          Styles.container,
          Styles.paddingTop0,
          { marginTop: 0 },
        ]}
      >
        <View style={{ alignItems: "center", paddingHorizontal: 50 }}>
          {isLoading ? (
            <View style={{}}>
              <ActivityIndicator
                isLoading
                size="small"
                color={Colors[theme].primary}
              />
            </View>
          ) : adList?.length ? (
            <View style={{ flexDirection: "row" }}>{renderList()}</View>
          ) : (
            <Button
              mode="contained"
              buttonColor={Colors.primary}
              onPress={() => {
                setAd({
                  status: true,
                  package: "Silver",
                  verticalBanners: [],
                  horizontalBanners: [],
                  audio: [],
                  title: "",
                  vendor: undefined,
                  ctaText: "",
                  ctaAction: "None",
                  ctaTarget: "",
                  credits: 0,
                  language: "All",
                });
                setAddAdsModalVisible(true);
              }}
            >
              Add Ads
            </Button>
          )}
        </View>
      </ScrollView>
    </Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    backgroundColor: "black",
    pading: 25,
    width: Layout.window.width * 0.5,
    height: Layout.window.height * 0.7,
    alignSelf: "center",
    borderWidth: 5,
    borderColor: Colors.primary,
    borderRadius: 10,
  },
  placeholderStyle: {
    fontSize: 16,
    color: Colors.grey,
  },
  selectedTextStyle: {
    fontSize: 16,
    backgroundColor: "transparent",
    color: Colors.white,
  },
});
