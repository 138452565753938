import { Dimensions } from 'react-native';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export default {
  window: {
    width,
    height,
  },
  tileSize: { width: width / 1.8, height: width / 1.8 },
  thumbnailLarge: { width: width * 0.7, height: width * 0.7 },
  listItemSize: { width: width - 10, height: 50 },
  thumbnailSmall: { width: 45, height: 45 },
  isSmallDevice: width < 375,
};
