/* eslint-disable no-nested-ternary */
/* eslint-disable react-native/no-inline-styles */
window.Buffer = window.Buffer || require("buffer").Buffer;
import { uuidv4 } from "@firebase/util";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";

import { FontAwesome } from "@expo/vector-icons";
import * as ImagePicker from "expo-image-picker";
import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import React, { useCallback, useEffect, useState } from "react";
import {
  FlatList,
  Image,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import { Modal, Portal, Provider } from "react-native-paper";

import { manipulateAsync } from "expo-image-manipulator";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import MultiSelectInput from "../components/MultiSelectInput";
import TextInput from "../components/TextInput";
import { ActivityIndicator, Text, View } from "../components/Themed";
import Colors from "../constants/Colors";
import Contents from "../constants/Contents";
import Layout from "../constants/Layout";
import Styles from "../constants/Styles";
import useColorScheme from "../hooks/useColorScheme";
import { Notif, RootTabScreenProps } from "../types";
import { Card } from "@chakra-ui/react";

//const analytics = getAnalytics(app);

export default function AdminNotificationList({
  navigation,
}: RootTabScreenProps<"Discover">) {
  const theme = useColorScheme();

  const [isLoading, setIsLoading] = useState(true);

  const [isScheduledNotif, setIsScheduledNotif] = useState<boolean>(false);
  const [testPushToken, setTestPushToken] = useState<string>();
  const [visible, setVisible] = useState(false);

  const [notifList, setNotifList] = useState<Notif[]>([]);
  const [notification, setNotification] = useState<Notif>();
  const [notificationIndex, setNotificationIndex] = useState<number>();
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);

  const db = getFirestore();

  const saveChanges = useCallback(async () => {
    if (!notification) {
      console.warn(notification);
      return;
    }
    if (!notification.title || !notification.message) {
      alert("Title and Message are mandatory");
      return;
    }

    const _notifList = notifList;
    if (notificationIndex === undefined) {
      _notifList.unshift(notification);
    } else {
      _notifList[notificationIndex] = notification;
    }

    await updateDoc(doc(db, "scheduledNotifications", "List"), {
      All: _notifList,
    }).then(() => {
      setNotifList([..._notifList]);
      setNotification(undefined);
      setNotificationIndex(undefined);
      hideModal();
      alert("Changes Saved");
    });
  }, [db, notifList, notification, notificationIndex]);

  const listItem = useCallback(
    ({ item, index }: { item: any; index: number }) => {
      return (
        <TouchableOpacity
          style={{
            width: Layout.window.width * 0.5,
            marginLeft: 5,
            flexDirection: "row",
            marginTop: 5,
          }}
          onPress={() => {
            setNotification(item);
            setNotificationIndex(index);
            showModal();
          }}
        >
          <Card>
            <View style={{ padding: 5 }}>
              <Text style={{ color: Colors.coral }}>{item.title}</Text>
              <Text
                style={{ width: Layout.window.width * 0.5 }}
                numberOfLines={3}
              >
                {item.message}
              </Text>
            </View>
          </Card>
        </TouchableOpacity>
      );
    },
    []
  );

  const renderList = useCallback(() => {
    return (
      <FlatList
        data={notifList}
        style={{
          marginTop: 10,
          paddingLeft: 50,
          height: Layout.window.height * 0.75,
          //marginBottom: 100 + ((playingNow.isPlayingNow || playingNow.Title || playingNow.live) ? 50 : 0)
        }}
        keyExtractor={(item, index) => index.toString() || ""}
        renderItem={listItem}
      />
    );
  }, [listItem, notifList]);

  async function uploadImageAsync(uri: string) {
    // Why are we using XMLHttpRequest? See:
    // https://github.com/expo/expo/issues/2402#issuecomment-443726662

    const manipResult = await manipulateAsync(
      uri,
      [{ resize: { width: 300 } }],
      { compress: 1 }
    );
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        console.log(e);
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", manipResult.uri, true);
      xhr.send(null);
    });

    const fileRef = ref(getStorage(), uuidv4());
    await uploadBytes(fileRef, blob);

    // We're done with the blob, close and release it
    //blob.close();

    return await getDownloadURL(fileRef);
  }

  const pickImage = useCallback(async (index?: number) => {
    // No permissions request is necessary for launching the image library
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });

    console.log(result);

    if (!result.canceled) {
      const image_url = await uploadImageAsync(result.uri);
      console.log(image_url);
      setNotification((cv) => {
        return { ...cv, image: image_url };
      });
    }
  }, []);

  useEffect(() => {
    function fetchMyAPI() {
      setIsLoading(true);

      getDoc(doc(db, "scheduledNotifications", "List")).then(
        (schdeuledNotifDoc) => {
          if (schdeuledNotifDoc.exists()) {
            const schdeuledNotif = schdeuledNotifDoc.data().All;
            setNotifList(schdeuledNotif);
            setIsLoading(false);
          }
        }
      );
    }

    fetchMyAPI();
  }, [db]);

  return (
    <Provider>
      <Portal>
        <Modal
          visible={visible}
          onDismiss={hideModal}
          contentContainerStyle={styles.modalContainer}
        >
          <ScrollView
            style={{ flex: 1 }}
            contentContainerStyle={{ padding: 20 }}
          >
            <Text style={{ fontSize: 25, marginBottom: 20 }}>
              {"Send Push Message"}
            </Text>
            <TextInput
              value={notification?.title || ""}
              label={"Push Title"}
              onChangeText={(value) =>
                setNotification((cv) => {
                  return { ...cv, title: value };
                })
              }
            />
            <TextInput
              value={notification?.message || ""}
              label={"Push Message"}
              onChangeText={(value) => {
                setNotification((cv) => {
                  return { ...cv, message: value };
                });
              }}
            />

            <MultiSelectInput
              label="Target Audience"
              options={["All", ...Contents.languages, "dev"]}
              values={[notification?.topic || "All"]}
              singleSelect
              onUpdate={(values: string[]) => {
                setNotification((cv) => {
                  return { ...cv, topic: values[0] };
                });
              }}
            />

            <TouchableOpacity
              style={{
                marginLeft: 20,
                marginTop: 30,
                marginBottom: 20,
                alignItems: "center",
              }}
              onPress={() => pickImage()}
            >
              {notification?.image ? (
                <Image
                  source={{ uri: notification.image }}
                  style={{ width: 100, height: 100, borderRadius: 15 }}
                />
              ) : (
                <FontAwesome name="image" size={100} color={Colors.primary} />
              )}
            </TouchableOpacity>
            <Text style={{ textAlign: "center" }}>Optional Image</Text>

            <MultiSelectInput
              label="Type"
              options={[
                "Info",
                "Activity",
                "Food",
                "Podcast",
                "Product",
                "Journal",
                "Task",
              ]}
              values={[notification?.type || "Info"]}
              singleSelect
              onUpdate={(values: string[]) => {
                setNotification((cv) => {
                  return { ...cv, type: values[0] };
                });
              }}
            />

            <MultiSelectInput
              label="Action"
              options={["Navigate", "Link", "None", "Premium"]}
              values={[notification?.action || "None"]}
              singleSelect
              onUpdate={(values: string[]) => {
                setNotification((cv) => {
                  return { ...cv, action: values[0] };
                });
              }}
            />
            <TextInput
              value={notification?.target || ""}
              label={"Action Target"}
              onChangeText={(value: string) => {
                setNotification((cv) => {
                  return { ...cv, target: value };
                });
              }}
            />
            <TextInput
              value={testPushToken || ""}
              label={"Test Push Token (For Testing)"}
              onChangeText={setTestPushToken}
            />
            <TouchableOpacity
              style={{
                paddingHorizontal: 10,
                paddingVertical: 5,
                backgroundColor: Colors.primary,
                borderRadius: 5,
                alignSelf: "center",
                marginVertical: 20,
              }}
              onPress={() => {
                if (!notification?.title || !notification?.message) {
                  return alert("Enter title and message");
                }
                if (isScheduledNotif) {
                  saveChanges();
                  return;
                }
                const functions = getFunctions();
                if (__DEV__) {
                  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
                }
                const sendNotification = httpsCallable(
                  functions,
                  "sendNotification"
                );
                sendNotification({
                  payload: {
                    message: {
                      topic: notification?.topic || "All",
                      notification: {
                        title: notification.title,
                        body: notification.message,
                      },
                      data: {
                        title: notification.title,
                        body: notification.message,
                        type: notification.type,
                        action: notification.action,
                        target: notification.target,
                        ...(notification.image && {
                          image: notification.image,
                        }),
                      },
                      android: {
                        notification: {
                          // click_action: "TOP_STORY_ACTIVITY",
                          body: notification.message,
                          ...(notification.image && {
                            image: notification.image,
                          }),
                        },
                      },
                      apns: {
                        payload: {
                          aps: {
                            category: "NEW_MESSAGE_CATEGORY",
                          },
                        },
                      },
                    },
                  },
                }).then((result) => {
                  // Read result of the Cloud Function.
                  /** @type {any} */
                  const data = result.data;
                  console.warn(result);
                  alert("Notification Sent");
                });
                // sendPushNotification(
                //   pushTitle,
                //   pushMessage,
                //   payload,
                //   testPushToken
                // );
              }}
            >
              <Text style={{ fontSize: 20 }}>
                {isScheduledNotif
                  ? "Save Scheduled Notification"
                  : "Send Message Now"}
              </Text>
            </TouchableOpacity>
          </ScrollView>
        </Modal>
      </Portal>
      <ScrollView
        contentContainerStyle={[
          Styles.container,
          Styles.paddingTop0,
          { marginTop: 0 },
        ]}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            paddingTop: 10,
            paddingRight: 20,
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity
              style={{
                paddingHorizontal: 10,
                paddingVertical: 5,
                backgroundColor: Colors.primary,
                borderRadius: 5,
                marginLeft: 25,
                alignSelf: "center",
                marginVertical: 20,
              }}
              onPress={() => {
                setNotification({
                  title: "",
                  message: "",
                  type: "Info",
                  action: "None",
                  topic: "All",
                });
                setTestPushToken(undefined);
                setIsScheduledNotif(false);
                showModal();
              }}
            >
              <Text style={{ fontSize: 20, textAlign: "center" }}>
                Send Adhoc Notifications
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={{
                paddingHorizontal: 10,
                paddingVertical: 5,
                backgroundColor: Colors.primary,
                borderRadius: 5,
                marginLeft: 25,
                alignSelf: "center",
                marginVertical: 20,
              }}
              onPress={() => {
                setNotification({
                  title: "",
                  message: "",
                  type: "Info",
                  action: "None",
                  topic: "All",
                });
                setNotificationIndex(undefined);
                setTestPushToken(undefined);
                setIsScheduledNotif(true);
                showModal();
              }}
            >
              <Text style={{ fontSize: 20, textAlign: "center" }}>
                Add new scheduled Notification
              </Text>
            </TouchableOpacity>

            {/* <TouchableOpacity
              onPress={() => {
                getDoc(doc(db, "NotifTokens", "token")).then((tokensDoc) => {
                  const allTokens = tokensDoc.data()?.tokens;
                  setDoc(doc(db, "NotifTokens", "token3"), {
                    tokens: allTokens,
                  }).then(() => {
                    alert("Done");
                  });
                });
              }}
            >
              <Text>Test</Text>
            </TouchableOpacity> */}
          </View>
        </View>
        <View style={{ alignItems: "center", paddingHorizontal: 50 }}>
          {isLoading ? (
            <View style={{}}>
              <ActivityIndicator
                isLoading
                size="small"
                color={Colors[theme].primary}
              />
            </View>
          ) : notifList?.length || notification ? (
            <View style={{ flexDirection: "row" }}>{renderList()}</View>
          ) : (
            <Text>No Scheduled Notifications</Text>
          )}
        </View>
      </ScrollView>
    </Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    backgroundColor: Colors.white,
    pading: 25,
    width: Layout.window.width * 0.5,
    height: Layout.window.height * 0.85,
    alignSelf: "center",
    borderWidth: 5,
    borderColor: Colors.primary,
    borderRadius: 10,
  },
});
