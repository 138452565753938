import React from "react";

import { FlatList, TouchableOpacity } from "react-native";

import { Text, View } from "./Themed";
import Colors from "../constants/Colors";
import Layout from "../constants/Layout";

type props = {
  values: string[];
  label: string;
  onUpdate: any;
  options: string[];
  singleSelect?: boolean;
  width?: number;
};

export default function MultiSelectInput({
  values,
  label,
  onUpdate,
  options,
  singleSelect,
  width,
}: props) {
  return (
    <View
      style={{
        padding: 10,
        marginLeft: 10,
        marginRight: 20,
      }}
    >
      <Text style={{ color: Colors.primary, fontSize: 16 }}>{label}</Text>
      <FlatList
        data={options}
        style={{
          marginTop: 5,
          flexWrap: "wrap",
          minHeight: 20,
          width: width || Layout.window.width / 2 - 30,
        }}
        contentContainerStyle={{ flexDirection: "row", flexWrap: "wrap" }} // align list items
        showsHorizontalScrollIndicator={false}
        renderItem={({ item, index }) => {
          return (
            <TouchableOpacity
              onPress={() => {
                if (singleSelect) {
                  onUpdate([item]);
                } else if (values?.includes(item)) {
                  onUpdate(values.filter((value) => value != item));
                } else {
                  onUpdate([...values, item]);
                }
              }}
              style={[
                {
                  borderColor: Colors.primary,
                  borderWidth: 1,
                  paddingHorizontal: 10,
                  marginRight: 10,
                  marginTop: 10,
                  borderRadius: 10,
                },
                values?.includes(item) && { backgroundColor: Colors.primary },
              ]}
            >
              <Text>{item}</Text>
            </TouchableOpacity>
          );
        }}
      />
    </View>
  );
}
