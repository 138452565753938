/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import * as React from "react";

import { Ionicons } from "@expo/vector-icons";
import { createDrawerNavigator } from "@react-navigation/drawer";
import {
  DarkTheme,
  DefaultTheme,
  DrawerActions,
  NavigationContainer,
  useNavigation,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { ColorSchemeName, Image, TouchableOpacity } from "react-native";
import Toast from "react-native-toast-message";
import { Text, View } from "../components/Themed";
import Colors from "../constants/Colors";
import AdminActivityList from "../screens/AdminActivityList";
import AdminFoodList from "../screens/AdminFoodList";
import AdminNotificationList from "../screens/AdminNotificationList";
import AdminPodcastList from "../screens/AdminPodcastList";
import WebHomeScreen from "../screens/HomeScreen.web";
import OnboardingForm from "../screens/OnboardingForm";
import ProductForm from "../screens/ProductForm";
import ProductList from "../screens/ProductList";
import TnC from "../screens/TnC";
import VendorList from "../screens/VendorList";
import { RootStackParamList } from "../types";
import LinkingConfiguration from "./LinkingConfiguration";
import { getAuth } from "firebase/auth";
import Contents from "../constants/Contents";
import Admin from "../screens/Admin";
import AdminHomescreenBanner from "../screens/AdminHomescreenBanner";
import OrdersList from "../screens/OrdersList";
import AdsList from "../screens/AdsList";
import UserList from "../screens/UserList";

export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  return (
    <>
      <NavigationContainer
        linking={LinkingConfiguration}
        // eslint-disable-next-line no-constant-condition
        theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
      >
        <RootNavigator />
      </NavigationContainer>
      <Toast />
    </>
  );
}

function Root() {
  return (
    <Drawer.Navigator initialRouteName="ProductList">
      <Drawer.Screen
        name="AdminPodcastList"
        component={AdminPodcastList}
        options={{ headerShown: false, title: "Podcasts" }}
      />
      <Drawer.Screen
        name="AdminFoodList"
        component={AdminFoodList}
        options={{ headerShown: false, title: "Meal Recipes" }}
      />
      <Drawer.Screen
        name="AdminActivityList"
        component={AdminActivityList}
        options={{ headerShown: false, title: "Activities" }}
      />
      <Drawer.Screen
        name="AdminNotificationList"
        component={AdminNotificationList}
        options={{ headerShown: false, title: "Notifications" }}
      />

      <Drawer.Screen
        name="VendorList"
        component={VendorList}
        options={{ headerShown: false, title: "Vendors" }}
      />
      <Drawer.Screen
        name="ProductList"
        component={ProductList}
        options={{ headerShown: false, title: "Products" }}
      />
      <Drawer.Screen
        name="Onboarding"
        component={OnboardingForm}
        options={{
          headerShown: false,
          title: "Add new Vendor",
          drawerItemStyle: { display: "none" },
        }}
      />
      <Drawer.Screen
        name="Product"
        component={ProductForm}
        options={{
          headerShown: false,
          title: "Add new Product",
          drawerItemStyle: { display: "none" },
        }}
      />
      <Drawer.Screen
        name="OrdersList"
        component={OrdersList}
        options={{ headerShown: false, title: "Orders" }}
      />
      <Drawer.Screen
        name="AdsList"
        component={AdsList}
        options={{ headerShown: false, title: "Ads" }}
      />
      <Drawer.Screen
        name="HomescreenBanner"
        component={AdminHomescreenBanner}
        options={{ headerShown: false, title: "Home Screen Banner" }}
      />
      <Drawer.Screen
        name="UserList"
        component={UserList}
        options={{ headerShown: false, title: "Users" }}
      />
    </Drawer.Navigator>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();
const Drawer = createDrawerNavigator();

function RootNavigator() {
  const navigation = useNavigation();
  return (
    <Stack.Navigator>
      {/* <Stack.Screen name="Admin" component={Admin} options={{ headerShown: false }} /> */}
      <Stack.Screen
        name="MonkiTox"
        component={WebHomeScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="TnC"
        component={TnC}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Admin"
        component={Admin}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="AdminStack"
        component={Root}
        options={{
          headerShown: true,
          header: () => (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: 10,
                paddingRight: 20,
              }}
            >
              <View
                style={{
                  backgroundColor: "transparent",
                  paddingHorizontal: 10,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity
                  onPress={() =>
                    navigation.dispatch(DrawerActions.toggleDrawer())
                  }
                >
                  <Ionicons name="menu" size={40} color={Colors.primary} />
                </TouchableOpacity>
                <Image
                  source={require("../assets/images/icon.png")}
                  style={{
                    height: 60,
                    width: 60,
                    borderRadius: 10,
                  }}
                />
                <View style={{ marginLeft: 10 }}>
                  <Text style={{ fontSize: 40 }} numberOfLines={1}>
                    {Contents.title}
                  </Text>
                  <Text style={{ color: Colors.coral, fontStyle: "italic" }}>
                    {Contents.subTitle}
                  </Text>
                </View>
              </View>
              <View>
                <Text>{`Logged in as ${
                  getAuth().currentUser?.phoneNumber
                }`}</Text>
                <TouchableOpacity
                  style={{ padding: 20 }}
                  onPress={() => {
                    getAuth()
                      .signOut()
                      .then(() => {
                        navigation.navigate("Admin");
                      });
                  }}
                >
                  <Text
                    style={{ color: "red", fontSize: 20, fontWeight: "bold" }}
                  >
                    Logout
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          ),
        }}
      />
    </Stack.Navigator>
  );
}
