/* eslint-disable prettier/prettier */
import { Timestamp } from "firebase/firestore";
import moment from "moment";
import { Linking } from "react-native";

import { DATE_FORMAT } from "../constants/Config";
import Contents from "../constants/Contents";
import { Product } from "../types";

export const getDefaultThumbnail = (index: number) => {
  return Contents.defaultThumbnails[index % Contents.defaultThumbnails.length];
};

export const _getAmountWithTax = (product: Product) => {
  return (
    Number(product.price) + Number(product.price) * (product.gst / 100 || 0)
  );
};

export function getFormattedPeriodSinceBirth(dob?: string) {
  const startDate = moment(dob, "DD/MM/YYYY");
  let duration = "";
  if (moment(Date.now()).diff(startDate, "months") <= 0) {
    duration = moment(Date.now()).diff(startDate, "days") + " Day(s)";
  } else if (moment(Date.now()).diff(startDate, "months") > 11) {
    duration = moment(Date.now()).diff(startDate, "years") + " Year(s)";
    if (moment(Date.now()).diff(startDate, "months") > 12) {
      duration +=
        " " + (moment(Date.now()).diff(startDate, "months") % 12) + "Month(s)";
    }
  } else {
    duration = moment(Date.now()).diff(startDate, "months") + " Month(s)";
  }
  return duration + " old";
}

export const _searchList = (list?: any[], text?: string) => {
  const searchArray = text?.split(" ");
  return list?.filter((item) =>
    searchArray?.every((searchWord) =>
      JSON.stringify(Object.values(item))
        .toLocaleLowerCase()
        .includes(searchWord.toLocaleLowerCase())
    )
  );
};

export const _contactWhatsApp = (message?: string) => {
  Linking.openURL(
    `http://api.whatsapp.com/send?${
      message ? `text=${message}&` : ""
    }phone=917695887107`
  ).catch((err) => console.log(err));
};

export const _getTimeStampString = (timestamp?: Timestamp) => {
  if (!timestamp) {
    return "";
  }
  if (timestamp instanceof Timestamp) {
    return moment(timestamp.toDate()).format(DATE_FORMAT);
  }
  if (timestamp?._seconds) {
    return moment(timestamp?._seconds * 1000).format(DATE_FORMAT);
  }
  return moment().format(DATE_FORMAT);
};
